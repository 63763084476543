enum ActionTypes {
  DANGER = 'danger',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface Action {
  disabled?: () => boolean;
  action: Function;
  text: string;
}

export type FooterActions = {
  [key in ActionTypes]?: Action;
};

export interface ModalStep {
  footerActions?: FooterActions;
}
