































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDFormInput extends Vue {
  @Prop({ type: String, required: false, default: '' })
  label!: string;
  @Prop({ type: String, required: false, default: '' })
  note!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconSrc!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconAlt!: string;
  @Prop({ type: String, required: false, default: '' })
  invalidText!: string;
  @Prop({ type: Boolean, required: false, default: false })
  invalid!: boolean;
}
