import { valuesEnum } from './utils';

export enum Actor {
  // Admin
  Admin = 'admin',
  // Patient
  UnguidedPatient = 'unguidedPatient',
  DiagnosticSessionPatient = 'diagnosticSessionPatient',
  OnlineAftercarePatient = 'onlineAftercarePatient',
  OnlineTherapyPatient = 'onlineTherapyPatient',
  CatamnesisPatient = 'catamnesisPatient',
  // Practitioner
  Diagnostician = 'diagnostician',
  OnlineTherapist = 'onlineTherapist',
  DiagnosticOnlineTherapist = 'diagnosticOnlineTherapist',
  // Counselling
  CounsellingClient = 'counsellingClient',
  Counsellor = 'counsellor',
}

export enum Community {
  Alpha = 'alpha',
  Beta = 'beta',
  Live = 'live',
}

export enum Gender {
  Female = 'female',
  Male = 'male',
  Unspecified = 'unspecified',
}

export enum InsuranceCompany {
  Allianz = 'Allianz',
  AokBayern = 'AOK Bayern',
  AokNiedersachsen = 'AOK Niedersachsen',
  Arag = 'ARAG',
  AudiBkk = 'Audi BKK',
  Axa = 'AXA',
  Barmenia = 'Barmenia',
  Barmer = 'BARMER',
  Gothaer = 'Gothaer',
  Hallesche = 'Hallesche',
  Knappschaft = 'Knappschaft',
  Mhplus = 'mhplus',
  SignalIduna = 'Signal Iduna',
  TK = 'Techniker Krankenkasse',
}

export const insuranceCompanyTermsToBeAccepted = [
  InsuranceCompany.Barmer,
  InsuranceCompany.Knappschaft,
  InsuranceCompany.AokNiedersachsen,
];

export enum InsuranceType {
  Private = 'private',
  Statutory = 'statutory',
}

export type JwtPayload = {
  actor: Actor;
  community: Community;
  fiat: Nullable<number>;
  group: UserGroup;
  legalTerms: LegalTerms;
  platform: Platform;
  roles: Role[];
  type: JwtType;
  uuid: Uuid;
};

export enum JwtType {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export interface Jwt extends JwtPayload {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: Uuid;
  sub: string;
}

export type LegalTerms = {
  gmbh: boolean;
  mvz: boolean;
};

export enum Role {
  CampaignManager = 'campaign.manager',
  ContentManager = 'content.manager',
  ContentClient = 'content.client',
  ContentOwner = 'content.owner',
  CounsellingClient = 'counsellingSession.client',
  Counsellor = 'counsellingSession.owner',
  DeeplinkManager = 'deeplink.manager',
  DiagnosticSessionClient = 'diagnosticSession.client',
  DiagnosticSessionOwner = 'diagnosticSession.owner',
  LocationManager = 'location.manager',
  PatientManager = 'patient.manager',
  ScreeningManager = 'screening.manager',
  ServiceManager = 'service.manager',
  Super = 'super',
  TherapistManager = 'therapist.manager',
  TherapySessionClient = 'therapySession.client',
  TherapySessionOwner = 'therapySession.owner',
  VideoChatClient = 'videoChat.client',
  VideoChatOwner = 'videoChat.owner',
  UnguidedManager = 'unguided.manager',
  UnguidedClient = 'unguided.client',
  UnguidedDigaClient = 'unguidedDiga.client',
}

export enum OtpOrigin {
  ChangeEmail = 'changeEmail',
  EmailConfirmation = 'emailConfirmation',
  Invite = 'invite',
  ForgotPassword = 'forgotPassword',
  Sms = 'sms',
}

export enum OtpState {
  Consumed = 'consumed',
  Expired = 'expired',
  Invalid = 'invalid',
  Valid = 'valid',
}

export enum Platform {
  Mobile = 'mobile',
  MobileWeb = 'mobileWeb',
  Web = 'web',
}

export enum MobileWebApp {
  Diga = 'diga',
  Int = 'international',
  Web = 'web',
}

export enum Title {
  DiplomateMedicine = 'diplomateMed',
  DiplomatePsychology = 'diplomatePsych',
  Doctor = 'doctor',
  Frau = 'frau',
  Herr = 'herr',
  MasterPsychology = 'masterPsych',
  Professor = 'professor',
}

export enum UserGroup {
  Admin = 'admin',
  Practitioner = 'practitioner',
  Patient = 'patient',
}

// Admin roles
export const adminRoleOptions: Role[] = [
  Role.ContentManager,
  Role.DeeplinkManager,
  Role.LocationManager,
  Role.PatientManager,
  Role.ScreeningManager,
  Role.ServiceManager,
  Role.Super,
  Role.TherapistManager,
  Role.UnguidedManager,
];

// Patient roles
export const patientRoleOptions: Role[] = [
  Role.ContentClient,
  Role.DiagnosticSessionClient,
  Role.TherapySessionClient,
  Role.UnguidedClient,
  Role.VideoChatClient,
];
export const unguidedPatientRoleOptions: Role[] = [Role.UnguidedClient];
export const unguidedDigaClientRoleOptions: Role[] = [Role.UnguidedDigaClient];
export const diagnosticSessionPatientRoleOptions: Role[] = [
  Role.ContentClient,
  Role.DiagnosticSessionClient,
  Role.UnguidedClient,
  Role.VideoChatClient,
];
export const onlineAftercarePatientRoleOptions: Role[] = [
  Role.ContentClient,
  Role.TherapySessionClient,
  Role.UnguidedClient,
  Role.VideoChatClient,
];
export const onlineTherapyPatientRoleOptions: Role[] = [
  Role.ContentClient,
  Role.TherapySessionClient,
  Role.UnguidedClient,
  Role.VideoChatClient,
];
export const catamnesisPatientRoleOptions: Role[] = [
  Role.ContentClient,
  Role.UnguidedClient,
  Role.VideoChatClient,
];

// Practitioner roles
export const practitionerRoleOptions: Role[] = [
  Role.ContentOwner,
  Role.DiagnosticSessionOwner,
  Role.TherapySessionOwner,
  Role.VideoChatOwner,
];
export const diagnosticianRoleOptions: Role[] = [
  Role.ContentOwner,
  Role.DiagnosticSessionOwner,
  Role.VideoChatOwner,
];
export const diagnosticOnlineTherapistRoleOptions: Role[] = [
  Role.ContentOwner,
  Role.DiagnosticSessionOwner,
  Role.TherapySessionOwner,
  Role.VideoChatOwner,
];
export const onlineTherapistRoleOptions: Role[] = [
  Role.ContentOwner,
  Role.TherapySessionOwner,
  Role.VideoChatOwner,
];

export const adminActorOptions: Actor[] = [Actor.Admin];

export const patientActorOptions: Actor[] = [
  Actor.CatamnesisPatient,
  Actor.DiagnosticSessionPatient,
  Actor.OnlineAftercarePatient,
  Actor.OnlineTherapyPatient,
  Actor.UnguidedPatient,
];

export const practitionerActorOptions: Actor[] = [
  Actor.DiagnosticOnlineTherapist,
  Actor.Diagnostician,
  Actor.OnlineTherapist,
];

export const communityOptions: Community[] = valuesEnum<Community>(Community);
export const genderOptions: Gender[] = valuesEnum<Gender>(Gender);
export const insuranceCompanyOptions: InsuranceCompany[] =
  valuesEnum<InsuranceCompany>(InsuranceCompany);
export const insuranceTypeOptions: InsuranceType[] = valuesEnum<InsuranceType>(InsuranceType);
export const titleOptions: Title[] = valuesEnum<Title>(Title);

//Ideal user inactivity time for Production
export const idleTime = 4200000; // 70 mintues (4200000 ms)
