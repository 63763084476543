/* Plugin to handle cases when translation don't exist during render
 * Example: In cases when assigning a translated value to a variable depending on the return
 * value of a promise and if the user navigates to another view before the promise is resolved,
 * then the $i18n for that view becomes null and throws an error when trying to retrieve
 * a translation for that translation key.
 * https://vuejs.org/v2/guide/plugins.html
 */

import { Actor } from '@minddocdev/lib-common/users';

const safeI18n = {
  install(vue: any) {
    /* eslint no-param-reassign: [2, {"props": false}] */
    vue.prototype.$t = function $t(...args: any[]) {
      if (!this.$i18n) return '';

      const message = this.$i18n.t(...args);
      // NOTE: vue i18n returns the key when the message is empty
      return message === args[0] ? '' : message;
    };

    /* eslint no-param-reassign: [2, {"props": false}] */
    vue.prototype.$d = function $t(...args: any[]) {
      return this.$i18n ? this.$i18n.d(...args) : '';
    };

    /* eslint no-param-reassign: [2, {"props": false}] */
    vue.prototype.$tr = function $tr(actor: Actor) {
      return this.$i18n.t(`webRoles.${actor}`);
    };
  },
};

export default safeI18n;
