














import { Component, Prop, Vue } from 'vue-property-decorator';

import MDInputText from '@minddocdev/lib-components/components/MDInputText.vue';
import { loadGoogleMaps } from '@minddocdev/lib-components/utils/gmaps.utils';

interface GMapsAddressComponents {
  street_number?: string;
  route: string;
  locality: string;
  postal_code?: string;
}

@Component({
  components: { MDInputText },
})
export default class MDInputGMapsAddress extends Vue {
  @Prop({ type: String, required: false, default: '' })
  autocomplete!: string;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String, required: false, default: '' })
  note!: string;
  @Prop({ type: String, required: false, default: '' })
  placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  invalid!: boolean;
  @Prop({ type: String, required: false, default: '' })
  invalidText!: string;
  /**
   * Show only street and number or full address line
   */
  @Prop({ type: Boolean, required: false, default: true })
  fullAddress!: boolean;
  @Prop({ required: false, type: String })
  apiKey!: string;
  /**
   * Use an app name to identify the app in gmaps console
   */
  @Prop({ required: false, type: String })
  appName!: string;

  /**
   * Plain address formatted as {street} {number}, {zip} {city}
   */
  @Prop({ required: false, type: String, default: '' })
  value!: string;
  /**
   * Use to restrict queries to a country. Value must be ISO code
   */
  @Prop({ required: false, type: String, default: 'de' })
  countryRestriction!: string;

  @Prop({ required: false, type: Boolean, default: false })
  clearFieldAfterSearch!: boolean;

  private readonly gMapsAddressComponents: GMapsAddressComponents = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    postal_code: 'short_name',
  };
  private gMapsAutocomplete!: google.maps.places.Autocomplete;
  private address: UI.Address = {
    street: '',
    number: '',
    zip: '',
    city: '',
    lat: 0,
    lng: 0,
  };

  addressLine = this.value;

  created() {
    this.initialize();
  }

  async initialize() {
    try {
      await loadGoogleMaps(this.apiKey, this.appName);
    } catch (error) {
      this.$emit('error', error);
    }
    this.initAutoComplete();
  }

  initAutoComplete() {
    const input = this.$el.querySelector('input') as HTMLInputElement;
    this.gMapsAutocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ['geocode'],
      componentRestrictions: { country: this.countryRestriction },
    });
    this.gMapsAutocomplete.setFields(['address_component', 'geometry']);
    this.gMapsAutocomplete.addListener('place_changed', this.addressChanged);
    // NOTE: Google autocomplete sets the autocomplete to off after loading.
    // We restore it using an attribute observer
    /* istanbul ignore next */
    const observer = new MutationObserver(function () {
      observer.disconnect();
      input.autocomplete = 'street-address';
    });
    /* istanbul ignore next */
    observer.observe(input, {
      attributes: true,
      attributeFilter: ['autocomplete'],
    });
  }

  addressChanged() {
    const place = this.gMapsAutocomplete.getPlace();
    const parsed: GMapsAddressComponents = {} as any;
    place.address_components!.forEach((field: google.maps.GeocoderAddressComponent) => {
      const addressType = field.types[0]! as keyof GMapsAddressComponents;
      const addressComponentForm = this.gMapsAddressComponents[
        addressType
      ] as keyof google.maps.GeocoderAddressComponent;
      if (addressComponentForm) {
        parsed[addressType] = field[addressComponentForm] as string;
      }
    });

    const location = place.geometry!.location;
    this.setAddress(parsed, location.lat(), location.lng());

    this.$emit('addressChanged', this.address);
    this.$emit('input', this.addressLine);
  }

  setAddress(parsed: GMapsAddressComponents, lat: number, lng: number) {
    this.address.city = parsed.locality;
    this.address.street = parsed.route;
    this.address.number = parsed.street_number || '';
    this.address.zip = parsed.postal_code || '';
    this.address.lat = lat;
    this.address.lng = lng;
    let streetLine = parsed.route;
    if (this.address.number) {
      streetLine = `${streetLine} ${this.address.number}`;
    }
    if (this.fullAddress) {
      if (this.address.zip && this.address.city) {
        streetLine = `${streetLine}, ${this.address.zip} ${this.address.city}`;
      } else if (this.address.zip) {
        streetLine = `${streetLine}, ${this.address.zip}`;
      } else if (this.address.city) {
        streetLine = `${streetLine}, ${this.address.city}`;
      }
    }

    this.addressLine = streetLine;
  }
}
