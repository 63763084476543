






































import { Component, Vue } from 'vue-property-decorator';

import { MDModalCloseButton } from '@minddocdev/lib-components';
import MDLogo from '@minddocdev/lib-components/components/MDLogo.vue';
import windowResizeHandler from '@minddocdev/lib-components/utils/resize-handler';

@Component({
  components: { MDLogo, MDModalCloseButton },
  name: 'MDNavigationMenu',
  mixins: [windowResizeHandler('.md-navigation-menu > .desktop-menu')],
})
export default class MDNavigationMenu extends Vue {
  showMenu = false;

  created() {
    this.$parent.$on('closeMenu', this.toggleMobileMenu);
  }

  toggleMobileMenu() {
    this.showMenu = !this.showMenu;
  }
}
