





























import { Component, Prop, Vue } from 'vue-property-decorator';

import MDGCSOrCldImage from '@minddocdev/lib-components/components/MDGCSOrCldImage.vue';
import MDTile from '@minddocdev/lib-components/components/MDTile.vue';

@Component({
  components: { MDTile, MDGCSOrCldImage },
  name: 'MDUserTile',
})
export default class MDUserTile extends Vue {
  /**
   * `user.response.User`
   */
  @Prop({ required: true, type: Object })
  user!: any;
  @Prop({ required: true, type: String })
  roles!: string;
  @Prop({ required: true, type: String })
  metaInfo!: string;

  get accountActivated() {
    return !!(this.user.firstName && this.user.lastName);
  }
  get title() {
    return this.accountActivated ? `${this.user.firstName} ${this.user.lastName}` : this.user.email;
  }

  get profileImage() {
    return this.user.avatarProfile ? this.user.avatarProfile : this.user.profilePicture;
  }

  get mode() {
    return this.user.avatarProfile ? 'gcs' : 'cld';
  }
}
