/* eslint-disable @typescript-eslint/naming-convention */
import { valuesEnum } from './utils';

export enum ValidStatusUpdate {
  UnAssigned = 'unAssigned',
  Assigned = 'assigned',
  InProgress = 'inProgress',
}

export enum ContentResultState {
  InProgress = 'inProgress',
  Completed = 'completed',
  Expired = 'expired',
}

export enum ContentResultStateV2 {
  Assigned = 'assigned',
  InProgress = 'inProgress',
  Completed = 'completed',
  Expired = 'expired',
  Read = 'read',
}

export enum Diagnosis {
  Anxiety = 'anxiety',
  Depression = 'depression',
  EatingDisorder = 'eatingDisorder',
  ObsessiveCompulsiveDisorder = 'obsessiveCompulsiveDisorder',
  Tinnitus = 'tinnitus',
}

export const diagnosisTranslation = {
  [Diagnosis.Anxiety]: 'Angststörung',
  [Diagnosis.Depression]: 'Depression',
  [Diagnosis.EatingDisorder]: 'Essstörung',
  [Diagnosis.ObsessiveCompulsiveDisorder]: 'Zwangsstörung',
  [Diagnosis.Tinnitus]: 'Tinnitus',
};

export enum LinkType {
  Therapy = 'therapy',
}

export enum TherapistContractModel {
  Berlin = 'ber', // Fixed salary therapist Berlin
  Freelance = 'hon', // Freelance therapist
  Munich = 'muc', // Fixed salary therapist Munich
  Remote = 'rem', // Fixed salary therapist Remote
}

export enum OnBoardingFlagType {
  HasDoctolibAppointment = 'hasDoctolibAppointment',
  IsTechInfoRead = 'isTechInfoRead',
  IsGeneralInfoRead = 'isGeneralInfoRead',
}

/**
 * The languages supported by the therapist in their therapy sessions.
 * TODO - These language strings should be ISO 639-1 codes at some point ('de', 'en', 'es'...)
 * Changing them requires a database migration
 * */
export enum Language {
  Arabic = 'ar',
  Chinese = 'zh',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Norwegian = 'no',
  Persian = 'fa',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Slovenian = 'sl',
  Spanish = 'es',
  Swedish = 'sv',
  Turkish = 'tr',
  Ukrainian = 'uk',
}

export enum DiseaseSpecialization {
  AcuteStressReactions = 'acuteStressReactions',
  Addiction = 'addiction',
  AttentionDeficitHyperactivityDisorder = 'adhd',
  Burnout = 'burnout',
  ChronicPain = 'chronicPain',
  ChronicSadness = 'chronicSadness',
  ChronicStress = 'chronicStress',
  ComplicatedSadness = 'complicatedSadness',
  DisturbancesOfSocialInteraction = 'disturbancesOfSocialInteraction',
  Insomnia = 'insomnia',
  MigrationAndEscape = 'migrationAndEscape',
  Pain = 'pain',
  PanicDisorder = 'panicDisorder',
  PersonalityAccentuations = 'personalityAccentuations',
  PersonalityDisorders = 'personalityDisorders',
  PhobicAnxieties = 'phobicAnxieties',
  PostTraumaticStressDisorder = 'postTraumaticStressDisorder',
  PsychoOncology = 'psychoOncology',
  PulseControlDisorders = 'pulseControlDisorders',
  SexualDysfunction = 'sexualDysfunction',
  SomatizationDisorders = 'somatizationDisorders',
  SubstanceAbuse = 'substanceAbuse',
  SubstanceMisuse = 'substanceMisuse',
  Trichotillomania = 'trichotillomania',
}

export enum TherapySpecialization {
  AcceptanceAndCommitmentTherapy = 'acceptanceAndCommitmentTherapy',
  AutogenicTraining = 'autogenicTraining',
  DialecticBehavioralTherapy = 'dialecticBehavioralTherapy',
  EmotionFocusedTherapy = 'emotionalFocussedTherapy',
  Hypnotherapy = 'hypnotherapy',
  Mindfulness = 'mindfulness',
  MindfulnessBasedStressReduction = 'mbsr',
  ProgressiveMuscleRelaxation = 'progressiveMuscleRelaxation',
  SchemaTherapy = 'schemaTherapy',
  StressManagementTraining = 'stressManagementTraining',
  TraumaTherapy = 'traumaTherapy',
}

export enum ResultState {
  InProgress = 'inProgress',
  Completed = 'completed',
  Expired = 'expired',
}

export enum QuestionnaireCategory {
  After18Sessions = 'after_18_sessions',
  After28Sessions = 'after_28_sessions',
  After38Sessions = 'after_38_sessions',
  After48Sessions = 'after_48_sessions',
  After8Sessions = 'after_8_sessions',
  BeforeFirstContact = 'before_first_contact',
  BeforeFirstContactAfter25July = 'before_first_contact_after25July',
  EndOfTherapy = 'end_of_therapy',
  MidOfTherapy = 'mid_of_therapy',
  Months12Catamnesis = '12_months_catamnesis',
  Months3Catamnesis = '3_months_catamnesis',
  Months6Catamnesis = '6_months_catamnesis',
  Phq_9 = 'phq_9',
  StartOfTherapy = 'start_of_therapy',
}

export enum ELearningCategory {
  BasicAssumptions = 'basic_assumptions',
  BehaviorConsequences = 'behavior_consequences',
  EducationEatingdisorder = 'education_eatingdisorder',
  Feelings = 'feelings',
  GettingActive = 'getting_active',
  Medication = 'medication',
  Nutrition = 'nutrition',
  Pain = 'pain',
  ProblemsolvingAcceptance = 'problemsolving_acceptance',
  EducationDepression = 'education_depression',
  RelapsePrevention = 'relapse_prevention',
  Sleeping = 'sleeping',
  ThoughtPatterns = 'thought_patterns',
}

export enum EndTherapyInternReason {
  dropout = 'dropout',
  default = 'default',
  transferOutInPatient = 'transfer-out-in-patient',
  ktChange = 'kt-change',
}

export enum EndTherapyExternReason {
  terminationOfInsured = 'termination-of-insured',
  terminationProvider = 'termination-provider',
  requirementsAreOmitted = 'requirements-are-omitted',
  deathOfChildOrInsured = 'death-of-child-or-insured',
  agreementsNotFulfilled = 'agreements-not-fulfilled',
  normalEnding = 'normal-ending',
  endOfInsurance = 'end-of-insurance',
  providerChange = 'provider-change',
}

export const schemaCategories: (QuestionnaireCategory | ELearningCategory)[] = [
  ...valuesEnum<QuestionnaireCategory>(QuestionnaireCategory),
  ...valuesEnum<ELearningCategory>(ELearningCategory),
];

export enum ContentType {
  Questionnaire = 'questionnaire',
  ELearning = 'elearning',
}

export enum ContentSubType {
  Course = 'course',
  Video = 'video',
}

export const validStatusUpdateOptions: ValidStatusUpdate[] =
  valuesEnum<ValidStatusUpdate>(ValidStatusUpdate);
export const contentResultStateOptions: ContentResultState[] =
  valuesEnum<ContentResultState>(ContentResultState);
export const diagnosisOptions: Diagnosis[] = valuesEnum<Diagnosis>(Diagnosis);
export const therapistContractModelOptions: TherapistContractModel[] =
  valuesEnum<TherapistContractModel>(TherapistContractModel);
export const languageOptions: Language[] = valuesEnum<Language>(Language);
export const diseasSpecializationOptions: DiseaseSpecialization[] =
  valuesEnum<DiseaseSpecialization>(DiseaseSpecialization);
export const therapySpecializationOptions: TherapySpecialization[] =
  valuesEnum<TherapySpecialization>(TherapySpecialization);

/**Changing date to 2050 as we have cancelled 100 MINUTE project */
//Questionnaire release date - Expecting to GO LIVE on 27th Sept 2023
export const questionnaireReleaseDate = '2050-09-27T00:00:00.000Z';
//Questionnaire release date - Changing this only for TESTING purpose on staging
// export const questionnaireReleaseDate = '2023-06-25T00:00:00.000Z';

//Bookable slot release date - 100 min bookable slots can be created when date is selected that 2 Oct or later - For Diagnostician
//Expecting to GO LIVE on 30th Aug 2023
export const releaseDateFor100Min = '2050-08-30T00:00:00.000Z';
//Bookable slot release date - Changing this only for TESTING purpose on staging
// export const releaseDateFor100Min = '2023-06-25T00:00:00.000Z';

//Bookable slot 100min date - Patient can book 100 min appointment
export const initialVersionOf100MinDate = '2050-10-02T00:00:00.000Z';
//Bookable slot 100min date - Changing this only for TESTING purpose on staging
// export const initialVersionOf100MinDate = '2023-07-01T00:00:00.000Z';

// New initial consultation goLive date
export const releaseNewInitConsultationDate = '2023-12-01T00:00:00.000Z';

//Expecting to GO LIVE on 1st Sept 2023
export const showBookableSlotMessageStartDate = '2050-09-01T00:00:00.00Z';
export const showBookableSlotMessageEndDate = '2050-09-26T00:00:00.00Z';
