



























































import { Component, Vue } from 'vue-property-decorator';

import { Jwt } from '@minddocdev/lib-common/users';
import { postLogin } from '@minddocdev/lib-web/api/services/users';
import { checkIsAppAcquisition, getAppForGroup } from '@minddocdev/lib-web/browser/url.utils';
import PasswordInput from '@minddocdev/lib-web/components/inputs/PasswordInput.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import jwtDecode from 'jwt-decode';

@Component({ components: { PasswordInput } })
export default class Login extends Vue {
  errorMessage = '';
  email = '';
  password = '';
  loading = false;

  get isButtonDisabled() {
    return !this.email || !this.password;
  }

  get isDigaUser() {
    const hostname = window.location.hostname;
    return hostname.includes('diga') || false;
  }

  get isAppAcquisition() {
    return checkIsAppAcquisition(this.$route);
  }

  onForgotPassword() {
    this.$router.push({ name: 'forgot-password' });
  }

  async login() {
    addBreadcrumb({
      message: 'Authenticating user',
      category: 'authentication',
    });
    this.loading = true;
    try {
      const tokens = await postLogin(this.email, this.password, true);
      const { group } = jwtDecode(tokens.accessToken) as Jwt;

      let redirectUrl = getAppForGroup(group, window.location.hostname);
      if (this.isAppAcquisition) {
        if (process.env.VUE_APP_OVERRIDE_TOKENS === 'true') {
          redirectUrl += `?accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}&isAppAcquisition=true`;
        } else {
          redirectUrl += `?isAppAcquisition=true`;
        }
      } else if (process.env.VUE_APP_OVERRIDE_TOKENS === 'true') {
        redirectUrl += `?accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}`;
      }
      window.location.href = redirectUrl;
    } catch (err) {
      this.loading = false;
      if (err.response) {
        const { status } = err.response;
        switch (status) {
          case 400:
          case 404:
            this.errorMessage = this.$t('error.invalidCredentials') as string;
            break;
          case 403:
          case 409:
            this.errorMessage = this.$t('error.notVerified') as string;
            break;
          default:
            this.errorMessage = this.$t('error.unexpected') as string;
            captureException(err);
            break;
        }
      } else {
        this.errorMessage = this.$t('error.unexpected') as string;
        captureException(err);
      }
    }
  }
}
