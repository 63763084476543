import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltFromBottom';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faTag as fasTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowAltFromBottom,
  faCheck,
  faInfoCircle,
  faSearch,
  fasCaretDown,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasEnvelope,
  fasPhone,
  fasTag,
  fasTimes,
  fasTimesCircle,
);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon);
