import { CancellationDetail, EventInstanceScope } from '@minddocdev/lib-common/calendar';
import { Language } from '@minddocdev/lib-common/therapy';
import { authTherapyApi, therapyApi } from '@minddocdev/lib-web/api/core';

// DELETE operations

export async function deleteTherapyAppointment(
  appointmentUuid: string,
  scope: EventInstanceScope,
  cancellationDetail?: CancellationDetail,
  explanation?: string,
): Promise<void> {
  await authTherapyApi.delete(`/web/calendar/therapy-appointments/${appointmentUuid}`, {
    params: { scope, cancellationDetail, explanation },
  });
}

export async function deleteCounsellingAppointment(
  counsellingAppointmentUuid: string,
  scope: EventInstanceScope,
  cancellationDetail?: CancellationDetail,
  explanation?: string,
): Promise<void> {
  await authTherapyApi.delete(
    `/web/calendar/counselling-appointments/${counsellingAppointmentUuid}`,
    {
      params: { scope, cancellationDetail, explanation },
    },
  );
}

export async function deleteBookableSlot(
  slotUuid: string,
  scope: EventInstanceScope,
): Promise<void> {
  await authTherapyApi.delete(`/web/calendar/bookable-slots/${slotUuid}?scope=${scope}`);
}

// GET operations

export async function getEvents(
  rangeStart: string,
  rangeEnd: string,
): Promise<RestTherapy.GetEventsResDto> {
  return (
    await authTherapyApi.get('/web/calendar/events', {
      params: {
        rangeStart,
        rangeEnd,
      },
    })
  ).data;
}

export async function getPatientTherapyAppointments(
  patientUuid: string,
): Promise<RestTherapy.TherapyAppointmentByAttendeeResDto> {
  return (await authTherapyApi.get(`/web/calendars/patients/${patientUuid}/therapy-appointments`))
    .data;
}

/* eslint-disable max-len */
export async function getTherapyAppointments(): Promise<RestTherapy.TherapyAppointmentByAttendeeResDto> {
  return (await authTherapyApi.get('/web/calendar/therapy-appointments')).data;
}

/* eslint-disable max-len */
export async function getCounsellingAppointments(): Promise<RestTherapy.TherapyAppointmentByAttendeeResDto> {
  return (await authTherapyApi.get('/web/calendar/counselling-appointments')).data;
}

export async function getPractitionerEvents(
  ownerUuid: string,
  rangeStart: Date | string,
  rangeEnd: Date | string,
): Promise<RestTherapy.GetEventsResDto> {
  return (
    await authTherapyApi.get(`/web/calendars/${ownerUuid}/events`, {
      params: { rangeStart, rangeEnd },
    })
  ).data;
}

export async function getDiagnosticBookableSlotsForLocations(
  locations: string[],
  rangeStart: string | Date,
  rangeEnd: string | Date,
  languages: Language[] = [],
): Promise<RestTherapy.BookableSlotWithPractitionerResDto[]> {
  const response = await authTherapyApi.get('/web/calendar/bookable-slots/diagnostic', {
    params: {
      rangeStart,
      rangeEnd,
      locations: locations.join(',') || undefined,
      languages: languages.join(',') || undefined,
    },
  });
  return response.data;
}

export async function getBookableSlotsOnlineTherapy(
  rangeStart: string,
  rangeEnd: string,
  languages: Language[] = [],
): Promise<RestTherapy.BookableSlotWithPractitionerResDto[]> {
  return (
    await authTherapyApi.get('/web/calendar/bookable-slots/online-therapy', {
      params: {
        rangeStart,
        rangeEnd,
        languages: languages.join(',') || undefined,
      },
    })
  ).data;
}

export async function getBookableSlotsCounselling(
  rangeStart: string,
  rangeEnd: string,
  languages: Language[] = [],
): Promise<RestTherapy.BookableSlotWithPractitionerResDto[]> {
  return (
    await authTherapyApi.get('/web/calendar/bookable-slots/counselling-session', {
      params: {
        rangeStart,
        rangeEnd,
        languages: languages.join(',') || undefined,
      },
    })
  ).data;
}

// PATCH operations

export async function patchTherapyAppointment(
  appointmentUuid: string,
  event: Partial<RestTherapy.EventReqDto>,
  therapyAppointment: Partial<RestTherapy.TherapyAppointmentReqDto>,
  recurrence?: Partial<RestTherapy.RecurrenceReqDto>,
  scope?: EventInstanceScope,
): Promise<void> {
  return (
    await authTherapyApi.patch(
      `/web/calendar/therapy-appointments/${appointmentUuid}?scope=${scope || ''}`,
      {
        event,
        therapyAppointment,
        recurrence,
      },
    )
  ).data;
}

export async function patchBookableSlot(
  slotUuid: string,
  event: Partial<RestTherapy.EventReqDto>,
  bookableSlot: Partial<RestTherapy.BookableSlotReqDto>,
  recurrence?: Partial<RestTherapy.RecurrenceReqDto>,
  scope?: EventInstanceScope,
): Promise<RestTherapy.UpdatedBookableSlotResDto> {
  return (
    await authTherapyApi.patch(`/web/calendar/bookable-slots/${slotUuid}?scope=${scope || ''}`, {
      event,
      bookableSlot,
      recurrence,
    })
  ).data;
}

export async function patchPractitionerTherapyAppointment(
  ownerUuid: string,
  appointmentUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
  appointment: RestTherapy.UpdateTherapyAppointmentReqDto,
): Promise<void> {
  await authTherapyApi.patch(
    `/web/calendars/${ownerUuid}/therapy-appointments/${appointmentUuid}`,
    appointment,
    { params: { scope } },
  );
}

export async function patchPractitionerBookableSlot(
  ownerUuid: string,
  bookableSlotUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
  bookableSlot: RestTherapy.UpdateBookableSlotReqDto,
): Promise<void> {
  await authTherapyApi.patch(
    `/web/calendars/${ownerUuid}/bookable-slots/${bookableSlotUuid}`,
    bookableSlot,
    { params: { scope } },
  );
}

// POST operations

export async function postAnonymousAppointment(
  event: RestTherapy.EventReqDto,
  anonymousAppointment: RestTherapy.AnonymousAppointmentReqDto,
): Promise<RestTherapy.AnonymousAppointmentResDto> {
  return (
    await authTherapyApi.post('/web/calendar/anonymous-appointments', {
      event,
      anonymousAppointment,
    })
  ).data;
}

export async function postTherapyAppointment(
  event: RestTherapy.EventReqDto,
  therapyAppointment: RestTherapy.TherapyAppointmentReqDto,
  recurrence?: RestTherapy.RecurrenceReqDto,
): Promise<RestTherapy.TherapyAppointmentResDto> {
  return (
    await authTherapyApi.post('/web/calendar/therapy-appointments', {
      event,
      therapyAppointment,
      recurrence,
    })
  ).data;
}

export async function postCounsellingAppointment(
  event: RestTherapy.EventReqDto,
  counsellingAppointment: RestTherapy.CounsellingAppointmentReqDto,
  recurrence?: RestTherapy.RecurrenceReqDto,
): Promise<RestTherapy.TherapyAppointmentResDto> {
  return (
    await authTherapyApi.post('/web/calendar/counselling-appointments', {
      event,
      counsellingAppointment,
      recurrence,
    })
  ).data;
}

export async function postBookableSlotDiagnostic(
  slotUuid: string,
  accessToken: string,
): Promise<RestTherapy.TherapyAppointmentWithPractitionerResDto> {
  return (
    await therapyApi.post(`/web/calendar/bookable-slots/${slotUuid}/diagnostic`, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).data;
}

export async function postBookableSlotOnlineTherapy(slotUuid: string): Promise<void> {
  return (await authTherapyApi.post(`/web/calendar/bookable-slots/${slotUuid}/online-therapy`))
    .data;
}

export async function postBookableSlotCounselling(slotUuid: string): Promise<void> {
  return (await authTherapyApi.post(`/web/calendar/bookable-slots/${slotUuid}/counselling`)).data;
}

export async function postBookableSlot(
  event: RestTherapy.EventReqDto,
  bookableSlot: RestTherapy.BookableSlotReqDto,
  recurrence?: RestTherapy.RecurrenceReqDto,
): Promise<RestTherapy.BookableSlotResDto[]> {
  return (
    await authTherapyApi.post('/web/calendar/bookable-slots', {
      event,
      bookableSlot,
      recurrence,
    })
  ).data;
}

export async function getOverlappingEvents(
  payload: Partial<RestTherapy.FindOverlappingEventsReqDto>,
): Promise<RestTherapy.MergedSortedEventsResDto['events'][]> {
  return (await authTherapyApi.post('/web/calendar/overlapping-events', payload)).data.events;
}

export async function postNonTherapyEvent(
  payload: RestTherapy.CreateNonTherapyEventReqDto,
): Promise<[RestTherapy.NonTherapyEventResDto]> {
  return (await authTherapyApi.post('/web/calendar/non-therapy-events', payload)).data;
}

export async function patchNonTherapyEvent(
  nonTherapyEventUuid: string,
  scope: EventInstanceScope,
  payload: RestTherapy.EditNonTherapyEventReqDto,
): Promise<[RestTherapy.NonTherapyEventResDto]> {
  return (
    await authTherapyApi.patch(`/web/calendar/non-therapy-events/${nonTherapyEventUuid}`, payload, {
      params: {
        scope,
      },
    })
  ).data;
}

export async function deleteNonTherapyEvent(
  nonTherapyEventUuid: string,
  scope: EventInstanceScope,
): Promise<void> {
  await authTherapyApi.delete(
    `/web/calendar/non-therapy-events/${nonTherapyEventUuid}?scope=${scope}`,
  );
}

export async function deletePractitionerTherapyAppointment(
  ownerUuid: string,
  appointmentUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
  cancellationDetail: RestTherapy.TherapyAppointmentResDto['cancelledBy'],
  explanation: string,
): Promise<void> {
  await authTherapyApi.delete(
    `/web/calendars/${ownerUuid}/therapy-appointments/${appointmentUuid}`,
    { params: { scope, cancellationDetail, explanation } },
  );
}

export async function deletePractitionerBookableSlot(
  ownerUuid: string,
  bookableSlotUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
): Promise<void> {
  await authTherapyApi.delete(`/web/calendars/${ownerUuid}/bookable-slots/${bookableSlotUuid}`, {
    params: { scope },
  });
}

export async function postPractitionerBookableSlot(
  ownerUuid: string,
  bookableSlot: RestTherapy.CreateBookableSlotReqDto,
): Promise<void> {
  await authTherapyApi.post(`/web/calendars/${ownerUuid}/bookable-slots`, bookableSlot);
}

export async function postPractitionerAnonymousAppointment(
  ownerUuid: string,
  payload: RestTherapy.CreateAnonymousAppointmentReqDto,
): Promise<void> {
  await authTherapyApi.post(`/web/calendar/${ownerUuid}/anonymous-appointments`, payload);
}

export async function postPractitionerTherapyAppointment(
  ownerUuid: string,
  payload: RestTherapy.CreateTherapyAppointmentReqDto,
): Promise<void> {
  await authTherapyApi.post(`/web/calendars/${ownerUuid}/therapy-appointments`, payload);
}

export async function postPractitionerNonTherapyEvent(
  ownerUuid: string,
  payload: RestTherapy.CreateNonTherapyEventReqDto,
): Promise<void> {
  await authTherapyApi.post(`/web/calendars/${ownerUuid}/non-therapy-events`, payload);
}

export async function postPractitionerOverlappingEvents(
  ownerUuid: string,
  payload: RestTherapy.FindOverlappingEventsReqDto,
): Promise<RestTherapy.MergedSortedEventsResDto['events'][]> {
  return (await authTherapyApi.post(`/web/calendars/${ownerUuid}/overlapping-events`, payload)).data
    .events;
}

export async function patchPractitionerNonTherapyEvent(
  ownerUuid: string,
  eventUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
  payload: RestTherapy.EditNonTherapyEventReqDto,
): Promise<void> {
  await authTherapyApi.patch(
    `/web/calendars/${ownerUuid}/non-therapy-events/${eventUuid}`,
    payload,
    { params: { scope } },
  );
}

export async function deletePractitionerNonTherapyEvent(
  ownerUuid: string,
  eventUuid: string,
  scope: RestTherapy.EventToExcludeReqDto['scope'],
): Promise<void> {
  await authTherapyApi.delete(`/web/calendars/${ownerUuid}/non-therapy-events/${eventUuid}`, {
    params: { scope },
  });
}
