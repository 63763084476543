import 'dayjs/locale/de';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);
dayjs.extend(isoWeek);

/* istanbul ignore next */
export function registerDayJsLocale(locale: string) {
  dayjs.locale(locale);
}
