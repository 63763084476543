import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

/* eslint-disable @typescript-eslint/no-explicit-any */
export function valuesEnum<E>(e: { [s: string]: any } | ArrayLike<any>): E[] {
  // Return of 'any' type given from the library itself
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.values(e);
}

/**
 * Check if the current date is before the due date,
 * considering both query parameters and hash routes.
 * @param queryParams - The query parameters from the URL.
 * @returns True if the current date is before the due date, otherwise false.
 */
export function isConsentFlow(queryParams?: Record<string, any>, currentDate: Date = new Date()) {
  // If the URL parameter is set to true, return false
  if (queryParams && queryParams.isAfterDueDate === 'true') {
    return false;
  }
  // Calculate the current date and the due date,
  // (due date is set to August 1st of the current year)
  const currentDateCEST = dayjs(currentDate).tz('Europe/Berlin');
  const dueDate = '2024-08-01T00:00:00+0200';
  // Check if the current date is before the due date
  return currentDateCEST.isBefore(dueDate);
}
