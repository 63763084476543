export type MapLocationColor = { [color in string]: string; };
export type MapLocationItem = {
  featureType: string;
  elementType: string;
  stylers: { color?: string; lightness?: number; saturation?: number; visibility?: string }[];
};

const color: MapLocationColor = {
  border: '#CACAC9',
  black: '#333333',
  darkerGrey: '#DEDEDE',
  darkGrey: '#E9E9E9',
  grey: '#F2F2F2',
  lightGrey: '#F5F5F5',
  lighterGrey: '#FEFEFE',
  water: '#CAD2D3',
  white: '#FFFFFF',
};

/**
 * For a quick intro on how to start styling a map, go to:
 * https://developers.google.com/maps/documentation/javascript/styling
 *
 * To see the complete reference for the styling options, visit:
 * https://developers.google.com/maps/documentation/javascript/style-reference
 *
 * To see a simple custom style example, go to:
 * https://developers.google.com/maps/documentation/javascript/examples/maptype-styled-simple
 */
export default [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{
      color: color.water,
    }, {
      lightness: 17,
    }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{
      color: color.lightGrey,
    }, {
      lightness: 20,
    }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{
      color: color.white,
    }],
  },
  // Without this rule, the parks would be green, building would be yellow, etc..
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{
      color: color.lightGrey,
    }, {
      lightness: 21,
    }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [{
      color: color.darkGrey,
    }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{
      visibility: 'on',
    }, {
      color: color.white,
    }, {
      lightness: 16,
    }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{
      saturation: 36,
    }, {
      color: color.black,
    }, {
      lightness: 40,
    }],
  },
  // We want to get rid of icons marking the highway
  // but we want to keep the road labels locally
  // as it helps to find where the clinic is
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{
      color: color.grey,
    }, {
      lightness: 19,
    }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [{
      color: color.lighterGrey,
    }, {
      lightness: 20,
    }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{
      color: color.darkerGrey,
    }, {
      weight: 1,
    }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [{
      color: color.border,
    }, {
      weight: 2,
    }],
  },
  // Remove business, tourist attractions, etc labels
  // MVV stops are still visible
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
] as MapLocationItem[];
