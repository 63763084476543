














































import Multiselect from 'vue-multiselect';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

type OpenDirection = 'top' | 'bottom';

/**
 * Component wrapper for https://vue-multiselect.js.org/
 */
@Component({
  components: { Multiselect },
  name: 'MDSelect',
})
export default class MDSelect extends Vue {
  /**
   * A list of options
   * @values any[]
   */
  @Prop({ type: Array, required: true })
  options!: any[];
  @Prop({ required: false, default: null })
  value!: any | null;
  @Prop({ type: String, required: false, default: null })
  placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;
  // Expose vue-multiselect props
  // https://vue-multiselect.js.org/#sub-props
  /**
   * [vue-multiselect] Clear the search input after select(). Use only when multiple is true.
   */
  @Prop({ type: Boolean, required: false, default: true })
  clearOnSelect!: boolean;
  /**
   * [vue-multiselect] Enable/disable closing after selecting an option
   */
  @Prop({ type: Boolean, required: false, default: true })
  closeOnSelect!: boolean;
  /**
   * [vue-multiselect] Default label field
   */
  @Prop({ required: false })
  label?: string;
  /**
   * [vue-multiselect] field to search when using the searchable prop
   */
  @Prop({ required: false })
  trackBy?: string;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Boolean, required: false, default: false })
  allowEmpty!: boolean;
  /**
   * [vue-multiselect]<br/>
   * Decide whether to filter the results internally based on search query.
   * Useful for async filtering, where we search through more complex data.
   */
  @Prop({ type: Boolean, required: false, default: true })
  internalSearch!: boolean;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Number, required: false, default: 300 })
  maxHeight!: number;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Boolean, required: false, default: false })
  multiple!: boolean;
  /**
   * [vue-multiselect]<br/>
   * Resets the internal value after each select action inside the component.
   */
  @Prop({ type: Boolean, required: false, default: false })
  resetAfter!: boolean;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Boolean, required: false, default: false })
  searchable!: boolean;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Number, required: false, default: 0 })
  tabindex!: number;
  /**
   * [vue-multiselect]<br/>
   * @values top, bottom
   */
  @Prop({ type: String, required: false, default: '' })
  openDirection!: OpenDirection;
  /**
   * [vue-multiselect]
   */
  @Prop({ type: Number, required: false })
  maxSelection?: number;

  private $multiSelectContent!: HTMLUListElement;

  internalValue: any = this.value;

  @Watch('value', { deep: true })
  watchValue() {
    this.internalValue = this.value;
  }

  mounted() {
    this.$multiSelectContent = this.$el.querySelector('.multiselect__content-wrapper')!;
  }

  customLabel(option: any) {
    if (this.trackBy && this.label) {
      if (option[this.label]) return option[this.label];

      const item = this.options.find((i: any) => i[this.trackBy!] === option[this.trackBy!]);
      return item ? item[this.label!] : '';
    }
    return option;
  }

  onOpen(ev: any) {
    // At this point the list is not shown yet so we schedule the code for 50ms
    setTimeout(() => {
      let selected = this.$multiSelectContent.querySelector(
        '.multiselect__option--selected',
      ) as HTMLElement;
      if (selected) {
        // The selected element is a span but we need to select its parent which is an LI element
        selected = selected.parentElement!;
        // Scroll the content to the selected element
        this.$multiSelectContent.scrollTop = selected.offsetTop;
      }
    }, 50);
    this.$emit('open', ev);
  }

  onSelect(option: any) {
    this.internalValue = option;
    /**
     * @param option {any}
     */
    this.$emit('select', this.internalValue);
  }

  onInput(option: any) {
    this.internalValue = option;
    /**
     * @param option {any}
     */
    this.$emit('input', this.internalValue);
  }

  onClose(value: any) {
    /**
     * @param option {any}
     */
    this.$emit('close', value);
    this.$emit('blur');
  }
}
