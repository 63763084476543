















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDInputRadio extends Vue {
  @Prop({ type: String, required: true })
  label!: string;
  @Prop({ type: Boolean, required: false, default: false })
  value!: boolean;
}
