






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDChip extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  logo!: boolean;
}
