import { Route } from 'vue-router';

import { UserGroup } from '@minddocdev/lib-common/users';

// (?:[0-9]+-)? matches prefixes on test environments
const MINDDOC_APP_REGEX = /(?:[0-9]+-)?(\w+)\..+\.([a-z]+)/i;
const APP_SUBSCRIPTION_ROUTE_REGEX = /(app-registration|app-subscription|app-redemption)/;

const DEV_HOST = RegExp('^localhost');
export enum MindDocApp {
  Accounts = 'ui-accounts',
  Admin = 'ui-admin',
  Booking = 'ui-booking',
  Therapy = 'ui-therapy',
  Api = 'api',
  Videochat = 'ui-videochat',
}
export enum WebsiteHost {
  Staging = 'https://staging.minddoc.com',
  Production = 'https://minddoc.com',
}
// TODO: Find a better way to import number from a configuration file
const DEV_ENV_APPS: Record<MindDocApp, string> = {
  [MindDocApp.Accounts]: 'http://localhost:8070',
  [MindDocApp.Admin]: 'http://localhost:8071',
  [MindDocApp.Booking]: 'http://localhost:8072',
  [MindDocApp.Therapy]: 'http://localhost:8073',
  [MindDocApp.Videochat]: 'http://localhost:8080',
  [MindDocApp.Api]: '', // An empty string means that the url will be relative
};
const DOCKER_ENV_APPS: Record<MindDocApp, string> = {
  [MindDocApp.Accounts]: 'http://localhost:8081',
  [MindDocApp.Admin]: 'http://localhost:8082',
  [MindDocApp.Booking]: 'http://localhost:8083',
  [MindDocApp.Therapy]: 'http://localhost:8084',
  [MindDocApp.Videochat]: 'http://localhost:8080',
  [MindDocApp.Api]: '',
};

const APP_TRANSLATION: Record<string, Record<MindDocApp, string>> = {
  de: {
    [MindDocApp.Accounts]: 'accounts',
    [MindDocApp.Admin]: 'admin',
    [MindDocApp.Booking]: 'booking',
    [MindDocApp.Therapy]: 'therapie',
    [MindDocApp.Api]: 'api',
    [MindDocApp.Videochat]: 'videochat',
  },
  default: {
    [MindDocApp.Accounts]: 'accounts',
    [MindDocApp.Admin]: 'admin',
    [MindDocApp.Booking]: 'booking',
    [MindDocApp.Therapy]: 'therapy',
    [MindDocApp.Api]: 'api',
    [MindDocApp.Videochat]: 'videochat',
  },
};

const ROLE_APP_TRANSLATION: Record<string, MindDocApp> = {
  admin: MindDocApp.Admin,
  therapist: MindDocApp.Therapy,
  patient: MindDocApp.Therapy,
};

const GROUP_APP_TRANSLATION: Record<UserGroup, MindDocApp> = {
  [UserGroup.Admin]: MindDocApp.Admin,
  [UserGroup.Patient]: MindDocApp.Therapy,
  [UserGroup.Practitioner]: MindDocApp.Therapy,
};

/**
 * Returns URL for {appName}. When in local environment, uses a predefined map <app: url>
 * @param appName MindDoc Web App
 * @param hostname Current hostname
 */
export function getMindDocAppUrl(appName: MindDocApp, hostname: string, forceEnvUrl?: string) {
  // Check first for dev envs. In development we serve apps in different ports.
  // These ports are known by the dev team.
  if (DEV_HOST.test(hostname)) {
    // Discover if environment is dev or docker based on the port
    const { host } = window.location;
    const port = host.split(':')[1];
    switch (port) {
      case '8081':
      case '8082':
      case '8083':
      case '8084':
        // These ports are defined in the docker-compose
        return DOCKER_ENV_APPS[appName];
      default:
        // Local dev has ports 8080 or 8060
        if (forceEnvUrl && appName === MindDocApp.Api) {
          DEV_ENV_APPS[appName] = forceEnvUrl;
        }
        return DEV_ENV_APPS[appName];
    }
  }

  /*
   * Specific case for redirect from minddoc.de|com to booking.minddoc.de|com
   * since MINDDOC_APP_REGEX does not handle this case.
   */
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  if (appName === MindDocApp.Booking && hostname.match(/^minddoc.(de|com)$/)) {
    return `https://${APP_TRANSLATION.de[appName]}.${hostname}`;
  }

  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const [, currentApp, toplevelDomain] = hostname.match(MINDDOC_APP_REGEX)!;
  if (toplevelDomain === 'de') {
    return `https://${hostname
      .replace(currentApp, APP_TRANSLATION.de[appName])
      .replace('accounts-', '')
      .replace('de', 'com')}`;
  }
  return `https://${hostname
    .replace(currentApp, APP_TRANSLATION.default[appName])
    .replace('accounts-', '')}`;
}

export function getAppForRole(role: string, hostname: string, forceEnvUrl?: string): string {
  return getMindDocAppUrl(ROLE_APP_TRANSLATION[role], hostname, forceEnvUrl);
}

export function openWebsite() {
  // TODO: Move url to a config/env file
  window.location.href = 'https://minddoc.com/de';
}

export function getAppForGroup(group: UserGroup, hostname: string, forceEnvUrl?: string): string {
  return getMindDocAppUrl(GROUP_APP_TRANSLATION[group], hostname, forceEnvUrl);
}

export function isTabletScreen(): boolean {
  return window.matchMedia('only screen and (min-width: 768px)').matches;
}

export function checkIsAppAcquisition(route: Route): boolean {
  return APP_SUBSCRIPTION_ROUTE_REGEX.test(route.path);
}

export function getWebRedirectURL(hostname: string) {
  if (DEV_HOST.test(hostname)) {
    return 'http://localhost:8080';
  } else if (hostname.includes('staging')) {
    return WebsiteHost.Staging;
  } else {
    return WebsiteHost.Production;
  }
}
