




import { Component, Prop, Vue } from 'vue-property-decorator';

import mapStyles from './MapLocationStyles';

type Size = {
  width: number;
  height: number;
};

@Component
export default class MDGStaticMap extends Vue {
  @Prop({ required: false, type: String })
  apiKey!: string;
  /**
   * Use an app name to identify the app in gmaps console
   */
  @Prop({ required: false, type: String })
  appName!: string;
  @Prop({ required: false, type: Object, default: () => ({ lat: 50, lng: 10 }) })
  center!: LatLng;
  @Prop({
    required: false,
    type: Object,
    default: () => ({ width: 700, height: 144 }),
  })
  size!: Size;
  /**
   * See https://developers.google.com/maps/documentation/maps-static/start?authuser=1#Zoomlevels
   */
  @Prop({ required: false, default: 15 })
  zoom!: number;
  @Prop({ required: false })
  markerList!: LatLng[];

  private readonly gmapsUrl = 'https://maps.googleapis.com/maps/api/staticmap?';
  private readonly markerIcon =
    'https://static.minddoc.com/takeshi/frontend/shared/custom-icons/map-pin.png';

  get mapCenter() {
    const { lat, lng } = this.center;
    return `center=${lat},${lng}`;
  }
  get mapSize() {
    const { width, height } = this.size;
    return `size=${width}x${height}`;
  }
  get markers() {
    let markerString = `markers=icon:${this.markerIcon}`;
    this.markerList.forEach(({ lat, lng }) => {
      markerString += `|${lat},${lng}`;
    });
    return markerString;
  }
  get styles() {
    let styles = '';
    mapStyles.forEach(({ featureType, elementType, stylers }) => {
      let styleString = '&style=';
      if (featureType) styleString += `feature:${featureType}|`;
      if (elementType) styleString += `element:${elementType}|`;
      const lastIdx = stylers.length - 1;
      stylers.forEach((style, idx) => {
        const [key, value] = Object.entries(style)[0];
        return (styleString += `${key}:${value}${idx < lastIdx ? '|' : ''}`);
      });
      styles += styleString;
    });
    return styles.replace(/#/g, '0x');
  }
  get url() {
    const { mapCenter, zoom, mapSize, markers, apiKey, gmapsUrl, styles } = this;
    const encodedParams = encodeURI(
      `${mapCenter}&zoom=${zoom}&${mapSize}&${markers}&${styles}&key=${apiKey}`,
    );
    return `${gmapsUrl}${encodedParams}`;
  }
}
