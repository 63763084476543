




import { Component, Vue } from 'vue-property-decorator';

import MDSiteHeader from '@minddocdev/lib-components/components/MDSiteHeader.vue';

@Component({ components: { MDSiteHeader } })
export default class SiteHeader extends Vue {}
