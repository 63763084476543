
















import { Component, Prop, Vue } from 'vue-property-decorator';

import MDLogo from '@minddocdev/lib-components/components/MDLogo.vue';

import { checkIsAppAcquisition } from '../../../lib-web/src/browser/url.utils';

@Component({
  components: { MDLogo },
})
export default class MDSiteHeader extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  logo!: boolean;

  $route: any;

  get isAppAcquisition() {
    return checkIsAppAcquisition(this.$route);
  }

  get logoColor() {
    return this.isAppAcquisition ? '#FF7F5C' : '#4C7F7F';
  }
}
