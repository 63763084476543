


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDProgressSpinner extends Vue {
  @Prop({ required: false, default: '' })
  classes!: boolean;
}
