import dayjs from 'dayjs';
import { extend, withValidation } from 'vee-validate';
import { email, max, min, required } from 'vee-validate/dist/rules.umd';

export function validateDate(value: any) {
  return !Number.isNaN(new Date(value).getDate());
}

/**
 * European insurance numbers format has at first position a letter [A-Z] followed by 9 digits
 */
export function validateInsuranceNumber(value: string) {
  return /[a-z]{1}[0-9]{9}/i.test(value);
}

export function validatePhoneNumber(value: string) {
  return /^\+?(\d\s?){7,20}$/.test(value);
}

// Verify postal codes for Germany.
export function validatePostalCode(value: string) {
  return /^\d{5}$/.test(value);
}

export function validateMinAge(value: string, { age }: any) {
  if (!value) return false;

  const date = dayjs(value);
  return dayjs().diff(date, 'year') >= age;
}

/**
 * Vee-validate interaction mode
 *
 * Validates inputs only when submitting the form. If inputs have errors it will validate
 * on input and blur events
 */
export function lazyPassive({ errors }: any) {
  if (errors.length) {
    return {
      on: ['input', 'blur'],
    };
  }

  return {
    on: [],
  };
}

/**
 * Creates HOC mapping validation props to MDComponent props
 * @param mdComponent {VueConstructor}
 */
export function mdComponentWithValidation(mdComponent: any) {
  return withValidation(mdComponent, ({ errors, failed }) => ({
    invalid: failed,
    invalidText: errors[0],
  }));
}

type SupportedRules =
  | 'date'
  | 'email'
  | 'insurance_number'
  | 'min'
  | 'max'
  | 'min_age'
  | 'phone_number'
  | 'required'
  | 'postal_code'
  | 'date_after'
  | 'positive'
  | 'zero_positive';

/**
 * Register vee-validate rules using messages from i18n instance.
 *
 * Validation messages must be prefixed with "validation" key
 * @param i18n {i18n}
 * @param rules {SupportedRules[]}
 */
export function registerRules(i18n: any, rules: SupportedRules[]) {
  const rulesSet = new Set(rules);

  if (rulesSet.has('date')) {
    extend('date', {
      message: i18n.t('validation.date'),
      validate: validateDate,
    });
  }

  if (rulesSet.has('date_after')) {
    extend('date_after', {
      validate: (value: string, { minDate }: any) => {
        if (!value || !minDate) return false;

        const date = dayjs(value);

        return date.isAfter(dayjs(minDate)) || date.isSame(dayjs(minDate), 'day');
      },
      params: ['minDate'],
      message: (_fieldName) => i18n.t('validation.date') as string,
    });
  }

  if (rulesSet.has('email')) {
    extend('email', {
      ...email,
      message: i18n.t('validation.email'),
    });
  }

  if (rulesSet.has('insurance_number')) {
    extend('insurance_number', {
      validate: validateInsuranceNumber,
      message: i18n.t('validation.healthInsurance') as string,
    });
  }

  if (rulesSet.has('max')) {
    extend('max', {
      ...max,
      params: ['length'],
      message: (_, { length }: any) => i18n.t('validation.max', { length }) as string,
    });
  }

  if (rulesSet.has('min')) {
    extend('min', {
      ...min,
      params: ['length'],
      message: (_, { length }: any) => i18n.t('validation.min', { length }) as string,
    });
  }

  if (rulesSet.has('min_age')) {
    extend('min_age', {
      validate: validateMinAge,
      params: ['age'],
      message: (_fieldName, { age }: any) => i18n.t('validation.minAge', { age }) as string,
    });
  }

  if (rulesSet.has('phone_number')) {
    extend('phone_number', {
      validate: validatePhoneNumber,
      message: i18n.t('validation.phoneNumber') as string,
    });
  }

  if (rulesSet.has('required')) {
    extend('required', {
      ...required,
      params: ['customKey'],
      /* istanbul ignore next */
      message(_field, { customKey }: any) {
        return i18n.t(`validation.${customKey || 'required'}`);
      },
    });
  }

  if (rulesSet.has('postal_code')) {
    extend('postal_code', {
      validate: validatePostalCode,
      message: i18n.t('validation.postalCode') as string,
    });
  }

  if (rulesSet.has('positive')) {
    extend('positive', {
      validate: (value: string) => Number(value) > 0,
      message: i18n.t('validation.positiveNumber') as string,
    });
  }

  if (rulesSet.has('zero_positive')) {
    extend('zero_positive', {
      validate: (value: string) => Number(value) >= 0,
      message: i18n.t('validation.zeroPositiveNumber') as string,
    });
  }
}
