



























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Action } from './types';

@Component({})
export default class Modal extends Vue {
  @Prop({ required: true })
  headerText!: string;

  @Prop({ required: false, default: () => ({}) })
  primaryAction: Action;

  @Prop({ required: false, default: () => ({}) })
  secondaryAction: Action;

  @Prop({ required: false, default: () => ({}) })
  dangerAction: Action;

  @Prop({ required: false, default: false })
  showBackButton: boolean;

  @Prop({ required: false, default: false })
  hideCloseButton: boolean;

  get isPrimaryDisabled() {
    return this.primaryAction?.disabled && this.primaryAction.disabled();
  }

  created() {
    document.addEventListener('keyup', this.closeOnEscape);
  }

  destroyed() {
    document.removeEventListener('keyup', this.closeOnEscape);
  }

  close() {
    this.$emit('close');
  }

  closeOnEscape(evt: KeyboardEvent) {
    if (evt.key === 'Escape') {
      this.close();
    }
  }
}
