import Vue from 'vue';
import VueRouter from 'vue-router';

import * as Sentry from '@sentry/vue';

type Environment = 'development' | 'staging' | 'qa' | 'production';

const PROD_URL_REGEX = new RegExp('^\\w+.minddoc.(com|de)');
const STAGING_URL_REGEX = new RegExp('^\\w+.staging.minddoc.(com|de)');

export function initSentry(dsn: string, router: VueRouter) {
  let environment: Environment = 'development';
  const { hostname } = window.location;
  if (PROD_URL_REGEX.test(hostname)) environment = 'production';
  if (STAGING_URL_REGEX.test(hostname)) environment = 'staging';

  if (environment === 'development') return;

  Sentry.init({
    Vue,
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      Sentry.replayIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    environment,
    release: process.env.VUE_APP_VERSION, // Commit hash
  });
}
