












import { Component, Vue } from 'vue-property-decorator';

import Footer from '@minddocdev/lib-web/components/Footer.vue';
import SiteHeader from '@minddocdev/ui-accounts/components/SiteHeader.vue';
import { registerDayJsLocale } from '@minddocdev/ui-accounts/plugins/dayjs';

@Component({
  components: {
    Footer,
    SiteHeader,
  },
})
export default class App extends Vue {
  created() {
    /* istanbul ignore next */
    registerDayJsLocale(this.$i18n.locale);
  }
}
