



























import { Component, Prop, Vue } from 'vue-property-decorator';

import MDCloudinaryImage from '@minddocdev/lib-components/components/MDCloudinaryImage.vue';
import MDGCSOrCldImage from '@minddocdev/lib-components/components/MDGCSOrCldImage.vue';

@Component({
  components: { MDCloudinaryImage, MDGCSOrCldImage },
  name: 'MDEmptyContent',
})
export default class MDEmptyContent extends Vue {
  @Prop({ required: true, type: String })
  title!: string;
  @Prop({ required: false, type: String })
  description!: string;
  @Prop({ required: false, type: String })
  imageSrc!: string;
  @Prop({ required: false, type: String, default: '' })
  buttonText!: string;
  @Prop({ required: false, type: Boolean, default: false })
  isGCSImage!: boolean;

  mode() {
    this.isGCSImage ? 'gcs' : 'cld';
  }
}
