





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import MDMiniCalendar from '@minddocdev/lib-components/components/date-time/MDMiniCalendar.vue';
import MDFormInput from '@minddocdev/lib-components/components/MDFormInput.vue';
import MDSelect from '@minddocdev/lib-components/components/MDSelect.vue';
import ClickOutside from '@minddocdev/lib-components/directives/click-outside';

import dayjs from 'dayjs';
import deLocale from 'dayjs/locale/de';
import enLocale from 'dayjs/locale/en';

@Component({
  components: { MDMiniCalendar, MDFormInput, MDSelect },
  directives: {
    ClickOutside,
  },
  name: 'MDInputDate',
})
export default class MDInputDate extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  invalid!: boolean;
  @Prop({ required: false, type: String, default: '' })
  invalidText!: string;
  @Prop({ required: false, type: String, default: '' })
  label!: string;
  @Prop({ required: false, type: String, default: 'en' })
  locale!: string;
  @Prop({ required: false, type: Number, default: 0 })
  yearsFromNow!: number;
  @Prop({ required: false, type: String, default: '' })
  placeholder!: string;
  @Prop({ required: false, type: [String, Date, Object] })
  value!: Date | string | null;
  @Prop({ required: false, type: String })
  dateFormat?: string;
  @Prop({ required: false, type: Boolean })
  allowFutureYearSelection?: boolean;
  @Prop({ required: false, type: Boolean, default: true })
  allowPastDateSelection?: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;
  @Prop({ required: false, type: Number, default: 6 })
  futureYears!: number;

  private readonly YEARS_RANGE = 120; // As suggested by designers
  private readonly FULL_DATE_FORMAT = 'dd, D. MMMM YYYY';

  innerDate = dayjs(this.value || new Date());
  displayDatePicker = false;
  blockFocusListener = false;

  get fullDate() {
    return this.innerDate.format(this.dateFormat ?? this.FULL_DATE_FORMAT);
  }
  get months() {
    const months = this.locale === 'en' ? enLocale.months! : deLocale.months!;
    return months.map((m, i) => ({ value: i, text: m, $isDisabled: this.isMonthDisabled(i) }));
  }
  get years() {
    const years = [];
    const currentYear = dayjs().year() - this.yearsFromNow;
    if (!!this.allowFutureYearSelection) {
      for (let y = currentYear; y < currentYear + this.futureYears; y += 1) {
        years.push(y);
      }
    } else {
      const minimumYear = currentYear - this.YEARS_RANGE + this.yearsFromNow;
      for (let y = currentYear; y > minimumYear; y -= 1) {
        years.push(y);
      }
    }
    return years;
  }
  get currentMonth() {
    return this.innerDate.month();
  }
  get currentYear() {
    return this.innerDate.year();
  }

  created() {
    if (this.innerDate.year() > dayjs().year() - this.yearsFromNow) {
      this.innerDate = this.innerDate.subtract(this.yearsFromNow, 'year');
    }
  }

  mounted() {
    // Update the value binding to this component right after mounting
    // Helps to trigger validations properly
    this.onInnerDateChanged();
  }

  onMonthChange(month: number) {
    this.innerDate = this.innerDate.month(month);
  }

  onYearChange(year: number) {
    const futureDate = this.innerDate.year(year);

    if (!this.allowPastDateSelection && futureDate.isBefore(dayjs())) {
      this.innerDate = dayjs();
      return;
    }

    this.innerDate = futureDate;
  }

  @Watch('innerDate')
  onInnerDateChanged() {
    this.$emit('input', this.innerDate.startOf('day').format('YYYY-MM-DD'));
  }

  @Watch('displayDatePicker')
  onDisplayDatePickerChanged() {
    if (this.displayDatePicker) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }

  isMonthDisabled(monthIndex: number) {
    if (this.allowPastDateSelection) {
      return false;
    }

    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();

    // this.currentYear is the year of the datepicker, not the current year
    return this.currentYear <= currentYear && currentMonth > monthIndex;
  }

  onDateChange(newDate: Date) {
    this.innerDate = dayjs(newDate);
  }

  toggleDatePicker() {
    this.displayDatePicker = !this.displayDatePicker;
  }

  openDatePicker() {
    if (this.blockFocusListener) {
      this.blockFocusListener = false;
    } else {
      this.displayDatePicker = true;
    }
  }

  closeDatePicker() {
    this.displayDatePicker = false;
  }
}
