import {
  Diagnosis,
  DiseaseSpecialization,
  Language,
  TherapistContractModel,
  TherapySpecialization,
} from '@minddocdev/lib-common/therapy';
import { Actor, Gender, Role, Title } from '@minddocdev/lib-common/users';
import { LocalizedEnum, SelectOption } from '@minddocdev/lib-web/lang/types';

function mapToOptions(messagesObject: any): SelectOption[] {
  return Object.keys(messagesObject).map((k) => ({ text: messagesObject[k], value: k }));
}

// By using generic types we enforce this to have translations in all the enums values
// Build will break if any value is added or removed
const DIAGNOSIS: LocalizedEnum<Diagnosis> = {
  de: {
    diagnosis: {
      [Diagnosis.Anxiety]: 'Angststörung',
      [Diagnosis.Depression]: 'Depression',
      [Diagnosis.EatingDisorder]: 'Essstörung',
      [Diagnosis.ObsessiveCompulsiveDisorder]: 'Zwangsstörung',
      [Diagnosis.Tinnitus]: 'Tinnitus',
    },
  },
  en: {
    diagnosis: {
      [Diagnosis.Anxiety]: 'Anxiety',
      [Diagnosis.Depression]: 'Depression',
      [Diagnosis.EatingDisorder]: 'Eating Disorder',
      [Diagnosis.ObsessiveCompulsiveDisorder]: 'Obsessive Compulsive Disorder',
      [Diagnosis.Tinnitus]: 'Tinnitus',
    },
  },
};

/* istanbul ignore next */
DIAGNOSIS.de.diagnosisOptions = mapToOptions(DIAGNOSIS.de.diagnosis);
/* istanbul ignore next */
DIAGNOSIS.en.diagnosisOptions = mapToOptions(DIAGNOSIS.en.diagnosis);

const GENDERS: LocalizedEnum<Gender> = {
  de: {
    genders: {
      [Gender.Female]: 'Weiblich',
      [Gender.Male]: 'Männlich',
      [Gender.Unspecified]: 'Divers',
    },
  },
  en: {
    genders: {
      [Gender.Female]: 'Female',
      [Gender.Male]: 'Male',
      [Gender.Unspecified]: 'Divers',
    },
  },
};

/* istanbul ignore next */
GENDERS.de.gendersOptions = mapToOptions(GENDERS.de.genders);
/* istanbul ignore next */
GENDERS.en.gendersOptions = mapToOptions(GENDERS.en.genders);

const TITLES: LocalizedEnum<Title> = {
  de: {
    titles: {
      [Title.DiplomateMedicine]: 'Dipl. Med.',
      [Title.DiplomatePsychology]: 'Dipl. Psych.',
      [Title.Doctor]: 'Dr.',
      [Title.Frau]: 'Frau',
      [Title.Herr]: 'Herr',
      [Title.MasterPsychology]: 'M.Sc. Psych.',
      [Title.Professor]: 'Prof.',
    },
  },
  en: {
    titles: {
      [Title.DiplomateMedicine]: 'Dipl. Med.',
      [Title.DiplomatePsychology]: 'Dipl. Psych.',
      [Title.Doctor]: 'Dr.',
      [Title.Frau]: 'Ms.',
      [Title.Herr]: 'Mr.',
      [Title.MasterPsychology]: 'M.Sc. Psych.',
      [Title.Professor]: 'Prof.',
    },
  },
};

/* istanbul ignore next */
TITLES.de.titlesOptions = mapToOptions(TITLES.de.titles);
/* istanbul ignore next */
TITLES.en.titlesOptions = mapToOptions(TITLES.en.titles);

const ROLES: LocalizedEnum<Role> = {
  de: {
    roles: {
      [Role.CampaignManager]: 'Campaign Manager',
      [Role.ContentClient]: 'Content Client',
      [Role.ContentManager]: 'Content Manager',
      [Role.ContentOwner]: 'Content Owner',
      [Role.CounsellingClient]: 'Klient:in',
      [Role.Counsellor]: 'Berater:in',
      [Role.DeeplinkManager]: 'Deeplink Manager',
      [Role.DiagnosticSessionClient]: 'Diagnostic Session Client',
      [Role.DiagnosticSessionOwner]: 'Diagnostic Session Owner',
      [Role.LocationManager]: 'Location Manager',
      [Role.PatientManager]: 'Patient Manager',
      [Role.ScreeningManager]: 'Screening Manager',
      [Role.ServiceManager]: 'Service Manager',
      [Role.Super]: 'Super',
      [Role.TherapistManager]: 'Therapist Manager',
      [Role.TherapySessionClient]: 'Therapy Session Client',
      [Role.TherapySessionOwner]: 'Therapy Session Owner',
      [Role.UnguidedClient]: 'Unguided Client',
      [Role.UnguidedManager]: 'Unguided Manager',
      [Role.UnguidedDigaClient]: 'Unguided DiGA User',
      [Role.VideoChatClient]: 'Video Chat Client',
      [Role.VideoChatOwner]: 'Video Chat Owner',
    },
  },
  en: {
    roles: {
      [Role.CampaignManager]: 'Campaign Manager',
      [Role.ContentClient]: 'Content Client',
      [Role.ContentManager]: 'Content Manager',
      [Role.ContentOwner]: 'Content Owner',
      [Role.CounsellingClient]: 'Client',
      [Role.Counsellor]: 'Counsellor',
      [Role.DeeplinkManager]: 'Deeplink Manager',
      [Role.DiagnosticSessionClient]: 'Diagnostic Session Client',
      [Role.DiagnosticSessionOwner]: 'Diagnostic Session Owner',
      [Role.LocationManager]: 'Location Manager',
      [Role.PatientManager]: 'Patient Manager',
      [Role.ScreeningManager]: 'Screening Manager',
      [Role.ServiceManager]: 'Service Manager',
      [Role.Super]: 'Super',
      [Role.TherapistManager]: 'Therapist Manager',
      [Role.TherapySessionClient]: 'Therapy Session Client',
      [Role.TherapySessionOwner]: 'Therapy Session Owner',
      [Role.UnguidedClient]: 'Unguided Client',
      [Role.UnguidedManager]: 'Unguided Manager',
      [Role.UnguidedDigaClient]: 'Unguided DiGA User',
      [Role.VideoChatClient]: 'Video Chat Client',
      [Role.VideoChatOwner]: 'Video Chat Owner',
    },
  },
};

const WEB_ROLES: LocalizedEnum<Actor> = {
  de: {
    webRoles: {
      [Actor.Admin]: 'Patientenmanager',
      [Actor.UnguidedPatient]: 'Unbegleiteter Nutzer',
      [Actor.DiagnosticSessionPatient]: 'Erstgesprächspatient',
      [Actor.OnlineAftercarePatient]: 'Nachsorgepatient',
      [Actor.OnlineTherapyPatient]: 'Onlinetherapie Patient',
      [Actor.CatamnesisPatient]: 'Katamnesepatient',
      [Actor.Diagnostician]: 'Erstgesprächspartner',
      [Actor.OnlineTherapist]: 'Onlinetherapeut',
      [Actor.DiagnosticOnlineTherapist]: 'Erstgesprächs- und Onlinetherapeut',
      [Actor.CounsellingClient]: 'Klient:in',
      [Actor.Counsellor]: 'Berater:in',
    },
  },
  en: {
    webRoles: {
      [Actor.Admin]: 'Patient manager',
      [Actor.UnguidedPatient]: 'Unguided User',
      [Actor.DiagnosticSessionPatient]: 'Diagnostic Patient',
      [Actor.OnlineAftercarePatient]: 'Aftercare Patient',
      [Actor.OnlineTherapyPatient]: 'Online Therapy Patient',
      [Actor.CatamnesisPatient]: 'Catamnesis Patient',
      [Actor.Diagnostician]: 'Diagnostic Partner',
      [Actor.OnlineTherapist]: 'Online Therapist',
      [Actor.DiagnosticOnlineTherapist]: 'Diagnostic- and Online Therapist',
      [Actor.CounsellingClient]: 'Client',
      [Actor.Counsellor]: 'Counsellor',
    },
  },
};

/* istanbul ignore next */
WEB_ROLES.de.webRolesOptions = mapToOptions(WEB_ROLES.de.webRoles);
/* istanbul ignore next */
WEB_ROLES.en.webRolesOptions = mapToOptions(WEB_ROLES.en.webRoles);

const DISEASE_SPECIALIZATION: LocalizedEnum<DiseaseSpecialization> = {
  de: {
    diseaseSpecializations: {
      [DiseaseSpecialization.PhobicAnxieties]: 'Angststörungen',
      [DiseaseSpecialization.SomatizationDisorders]: 'Somatisierungsstörungen',
      [DiseaseSpecialization.Pain]: 'Schmerzstörungen',
      [DiseaseSpecialization.ChronicPain]: 'Chronische Schmerzstörungen',
      [DiseaseSpecialization.SexualDysfunction]: 'Sexuelle Funktionsstörungen',
      [DiseaseSpecialization.Burnout]: 'Burnout',
      [DiseaseSpecialization.ChronicStress]: 'Chronischer Stress',
      [DiseaseSpecialization.DisturbancesOfSocialInteraction]: 'Beziehungs-/Interaktionsstörungen',
      [DiseaseSpecialization.PersonalityAccentuations]: 'Persönlichkeitsakzentuierungen',
      [DiseaseSpecialization.PersonalityDisorders]: 'Persönlichkeitsstörungen',
      [DiseaseSpecialization.PulseControlDisorders]: 'Impulskontrollstörungen',
      [DiseaseSpecialization.Trichotillomania]: 'Trichotillomanie',
      [DiseaseSpecialization.PostTraumaticStressDisorder]:
        'Post-traumatische Belastungsstörung(PTBS)',
      [DiseaseSpecialization.AcuteStressReactions]: 'Akute Belastungsreaktionen',
      [DiseaseSpecialization.Addiction]: 'Substanzabhängigkeit',
      [DiseaseSpecialization.SubstanceMisuse]: 'Substanzmissbrauch',
      [DiseaseSpecialization.AttentionDeficitHyperactivityDisorder]: 'ADHS',
      [DiseaseSpecialization.Insomnia]: 'Schlafstörungen',
      [DiseaseSpecialization.PsychoOncology]: 'Psychoonkologie',
      [DiseaseSpecialization.ComplicatedSadness]: 'Komplizierte Trauer',
      [DiseaseSpecialization.ChronicSadness]: 'Chronische Traurigkeit',
      [DiseaseSpecialization.MigrationAndEscape]: 'Flucht und Vertreibung',
      [DiseaseSpecialization.PanicDisorder]: 'Panikstörung',
      [DiseaseSpecialization.SubstanceAbuse]: 'Drogenmissbrauch',
    },
  },
  en: {
    diseaseSpecializations: {
      [DiseaseSpecialization.PhobicAnxieties]: 'Phobic Anxieties',
      [DiseaseSpecialization.SomatizationDisorders]: 'Somatization Disorders',
      [DiseaseSpecialization.Pain]: 'Pain',
      [DiseaseSpecialization.ChronicPain]: 'Chronic pain',
      [DiseaseSpecialization.SexualDysfunction]: 'Sexual dysfunction',
      [DiseaseSpecialization.Burnout]: 'Burnout',
      [DiseaseSpecialization.ChronicStress]: 'Chronic Stress',
      [DiseaseSpecialization.DisturbancesOfSocialInteraction]: 'Disturbances of Social Interaction',
      [DiseaseSpecialization.PersonalityAccentuations]: 'Personality accentuations',
      [DiseaseSpecialization.PersonalityDisorders]: 'Personality disorders',
      [DiseaseSpecialization.PulseControlDisorders]: 'Pulse control disorders',
      [DiseaseSpecialization.Trichotillomania]: 'Trichotillomania',
      [DiseaseSpecialization.PostTraumaticStressDisorder]: 'Post-traumatic stress disorder (PTSD)',
      [DiseaseSpecialization.AcuteStressReactions]: 'Acute stress reactions',
      [DiseaseSpecialization.Addiction]: 'Addiction',
      [DiseaseSpecialization.SubstanceMisuse]: 'Substance misuse',
      [DiseaseSpecialization.AttentionDeficitHyperactivityDisorder]: 'ADHD',
      [DiseaseSpecialization.Insomnia]: 'Insomnia',
      [DiseaseSpecialization.PsychoOncology]: 'Psycho oncology',
      [DiseaseSpecialization.ComplicatedSadness]: 'Complicated Sadness',
      [DiseaseSpecialization.ChronicSadness]: 'Chronic sadness',
      [DiseaseSpecialization.MigrationAndEscape]: 'Migration and Escape',
      [DiseaseSpecialization.PanicDisorder]: 'Panic Disorder',
      [DiseaseSpecialization.SubstanceAbuse]: 'Substance abuse',
    },
  },
};

/* istanbul ignore next */
DISEASE_SPECIALIZATION.de.diseaseSpecializationOptions = mapToOptions(
  DISEASE_SPECIALIZATION.de.diseaseSpecializations,
);

/* istanbul ignore next */
DISEASE_SPECIALIZATION.en.diseaseSpecializationOptions = mapToOptions(
  DISEASE_SPECIALIZATION.en.diseaseSpecializations,
);

// TODO: Check why the enum has more languages. The ones below were extracted from @locale/Languages.yaml
const LANGUAGES: any = {
  de: {
    languages: {
      [Language.Arabic]: 'Arabisch',
      [Language.German]: 'Deutsch',
      [Language.English]: 'Englisch',
      [Language.Spanish]: 'Spanisch',
      [Language.French]: 'Französisch',
      [Language.Italian]: 'Italienisch',
      [Language.Dutch]: 'Niederländisch',
      [Language.Russian]: 'Russisch',
      [Language.Turkish]: 'Türkisch',
    },
  },
  en: {
    languages: {
      [Language.Arabic]: 'Arabic',
      [Language.German]: 'German',
      [Language.English]: 'English',
      [Language.Spanish]: 'Spanish',
      [Language.French]: 'French',
      [Language.Italian]: 'Italian',
      [Language.Dutch]: 'Dutch',
      [Language.Russian]: 'Russian',
      [Language.Turkish]: 'Turkish',
    },
  },
};

/* istanbul ignore next */
LANGUAGES.de.languagesOptions = mapToOptions(LANGUAGES.de.languages);
/* istanbul ignore next */
LANGUAGES.en.languagesOptions = mapToOptions(LANGUAGES.en.languages);

const THERAPY_SPECIALIZATIONS: LocalizedEnum<TherapySpecialization> = {
  de: {
    therapySpecializations: {
      [TherapySpecialization.Hypnotherapy]: 'Hypnosetherapie',
      [TherapySpecialization.SchemaTherapy]: 'Schematherapie',
      [TherapySpecialization.StressManagementTraining]: 'Stress Management',
      [TherapySpecialization.AcceptanceAndCommitmentTherapy]:
        'Akzeptanz und Commitment Therapie(ACT)',
      [TherapySpecialization.MindfulnessBasedStressReduction]: 'Mindfulness Based Stress Reduction',
      [TherapySpecialization.AutogenicTraining]: 'Autogenes Training',
      [TherapySpecialization.ProgressiveMuscleRelaxation]: 'Progressive Muskelrelaxation PMR',
      [TherapySpecialization.Mindfulness]: 'Achtsamkeit',
      [TherapySpecialization.EmotionFocusedTherapy]: 'Emotions-fokussierte Therapie',
      [TherapySpecialization.DialecticBehavioralTherapy]: 'Dialektisch-behaviorale Therapie (DBT)',
      [TherapySpecialization.TraumaTherapy]: 'Traumatherapie EMDR/IRRT',
    },
  },
  en: {
    therapySpecializations: {
      [TherapySpecialization.Hypnotherapy]: 'Hypnotherapy',
      [TherapySpecialization.SchemaTherapy]: 'Schema Therapy',
      [TherapySpecialization.StressManagementTraining]: 'Stress Management Training',
      [TherapySpecialization.AcceptanceAndCommitmentTherapy]:
        'Acceptance and Commitment Therapy (ACT)',
      [TherapySpecialization.MindfulnessBasedStressReduction]: 'Mindfulness Based Stress Reduction',
      [TherapySpecialization.AutogenicTraining]: 'Autogenic Training',
      [TherapySpecialization.ProgressiveMuscleRelaxation]: 'Progressive muscle relaxation PMR',
      [TherapySpecialization.Mindfulness]: 'Mindfulness',
      [TherapySpecialization.EmotionFocusedTherapy]: 'Emotional Focussed Therapy',
      [TherapySpecialization.DialecticBehavioralTherapy]: 'Dialectic Behavioral Therapy',
      [TherapySpecialization.TraumaTherapy]: 'Trauma therapy EMDR/IRRT',
    },
  },
};

/* istanbul ignore next */
THERAPY_SPECIALIZATIONS.de.therapySpecializationOptions = mapToOptions(
  THERAPY_SPECIALIZATIONS.de.therapySpecializations,
);
/* istanbul ignore next */
THERAPY_SPECIALIZATIONS.en.therapySpecializationOptions = mapToOptions(
  THERAPY_SPECIALIZATIONS.en.therapySpecializations,
);

const THERAPIST_CONTRACT_MODELS: LocalizedEnum<TherapistContractModel> = {
  de: {
    therapistContractModels: {
      [TherapistContractModel.Berlin]: '(BER) Festangestellt Berlin',
      [TherapistContractModel.Freelance]: '(HON) Honorartherapeut',
      [TherapistContractModel.Munich]: '(MUC) Festangestellt München',
      [TherapistContractModel.Remote]: '(REM) Festangestellt Remote',
    },
  },
  en: {
    therapistContractModels: {
      [TherapistContractModel.Berlin]: '(BER) Fixed salary therapist Berlin',
      [TherapistContractModel.Freelance]: '(HON) Freelance therapist',
      [TherapistContractModel.Munich]: '(MUC) Fixed salary therapist Munich',
      [TherapistContractModel.Remote]: '(REM) Fixed salary therapist Remote',
    },
  },
};

/* istanbul ignore next */
THERAPIST_CONTRACT_MODELS.de.therapistContractModelsOptions = mapToOptions(
  THERAPIST_CONTRACT_MODELS.de.therapistContractModels,
);
/* istanbul ignore next */
THERAPIST_CONTRACT_MODELS.en.therapistContractModelsOptions = mapToOptions(
  THERAPIST_CONTRACT_MODELS.en.therapistContractModels,
);

// TODO: Deep freeze this object to avoid any run-time change in nested objects
/* istanbul ignore next */
Object.freeze(DIAGNOSIS);
/* istanbul ignore next */
Object.freeze(DISEASE_SPECIALIZATION);
/* istanbul ignore next */
Object.freeze(GENDERS);
/* istanbul ignore next */
Object.freeze(LANGUAGES);
/* istanbul ignore next */
Object.freeze(ROLES);
/* istanbul ignore next */
Object.freeze(THERAPY_SPECIALIZATIONS);
/* istanbul ignore next */
Object.freeze(TITLES);
/* istanbul ignore next */
Object.freeze(WEB_ROLES);
/* istanbul ignore next */
Object.freeze(THERAPIST_CONTRACT_MODELS);

export {
  DIAGNOSIS,
  DISEASE_SPECIALIZATION,
  GENDERS,
  LANGUAGES,
  ROLES,
  THERAPIST_CONTRACT_MODELS,
  THERAPY_SPECIALIZATIONS,
  TITLES,
  WEB_ROLES,
};
