














import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Search box input.
 */
@Component
export default class MDSearchBox extends Vue {
  @Prop({ type: String, default: '' })
  placeholder!: string;
}
