

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

type Day = {
  notOnThisMonth: boolean;
  number: number;
  selected: boolean;
  today: boolean;
  isDisabled: boolean;
};
const WEEKS_PER_MONTH = 6;
const DAYS_PER_WEEK = 7;

dayjs.extend(isoWeek);

@Component
export default class MDMiniCalendar extends Vue {
  /**
   * Default date to render calendar
   */
  @Prop({ required: false, type: [Date, String, Object], default: () => null })
  date!: Date | string;
  @Prop({ required: false, type: String, default: 'en' })
  locale!: string;
  @Prop({ required: false, type: Boolean, default: true })
  weekDays!: boolean;
  @Prop({ required: false, type: Boolean, default: true })
  allowPastDateSelection!: boolean;

  private readonly today = dayjs();
  private readonly DE_WEEK_DAYS = ['M', 'D', 'M', 'D', 'F', 'S', 'S'];
  private readonly EN_WEEK_DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  // Max number of days shown for a month, if this is exceeded, we render the next or prev month
  private readonly MAX_DAYS_SHOWN = 20;

  // Tracks the current month layout
  innerDate: dayjs.Dayjs | null = null;
  // Tracks the selected date if any
  selectedDate: dayjs.Dayjs | null = null;
  monthLayout: Day[][] = [];

  get weekDaysStrings() {
    return this.locale === 'en' ? this.EN_WEEK_DAYS : this.DE_WEEK_DAYS;
  }
  get weekKey() {
    return this.innerDate!.format('YYYY-MM');
  }

  created() {
    this.innerDate = this.date ? dayjs(this.date) : this.today.clone();
    this.selectedDate = this.date ? this.innerDate.clone() : null;
  }

  @Watch('date')
  onDateChanged() {
    this.innerDate = dayjs(this.date);
    this.selectedDate = this.innerDate.clone();
  }

  @Watch('innerDate')
  onInnerDateChange() {
    this.generateMonthLayout();
  }

  /**
   * Goes to the previous month
   * @public
   */
  prevMonth() {
    this.innerDate = this.innerDate!.subtract(1, 'month');
  }

  /**
   * Goes to the next month
   * @public
   */
  nextMonth() {
    this.innerDate = this.innerDate!.add(1, 'month');
  }

  select(day: Day) {
    if (day.isDisabled && !this.allowPastDateSelection) return;
    if (day.notOnThisMonth) {
      // After the 20th day, render the prev or next month
      if (day.number > this.MAX_DAYS_SHOWN) {
        this.prevMonth();
      } else {
        this.nextMonth();
      }
    }
    this.selectedDate = this.innerDate!.date(day.number);
    /**
     * @property {Date}
     */
    this.$emit('change', this.selectedDate.toDate());
    this.generateMonthLayout();
  }

  generateMonthLayout() {
    const monthLayout: Day[][] = [];
    // ISO weeks starts from 1 instead of 0, that's why the -1.
    const firstDayOfMonthIndex = this.innerDate!.startOf('month').isoWeekday() - 1;
    const totalDaysInMonth = this.innerDate!.daysInMonth();
    const month = this.innerDate!.month();
    const year = this.innerDate!.year();

    let dayCount = 1;
    let nextMonthCount = 1;
    let previousMonthCount =
      this.innerDate!.subtract(1, 'month').daysInMonth() - (firstDayOfMonthIndex - 1);
    // The idea here is to count back from the available days
    // to the end of the past month which will be 1 day before the first day of current month.
    let weekDays: Day[] = [];
    let weekDay: Day;
    let todayISOString = '';
    for (let week = 0; week < WEEKS_PER_MONTH; week += 1) {
      weekDays = Array<Day>(DAYS_PER_WEEK);
      for (let dayOfWeek = 0; dayOfWeek < DAYS_PER_WEEK; dayOfWeek += 1) {
        weekDay = {
          number: 0,
          notOnThisMonth: true,
          today: false,
          selected: false,
          isDisabled: false,
        };
        if (week === 0 && dayOfWeek < firstDayOfMonthIndex) {
          // Day belongs to previous month
          weekDay.number = previousMonthCount;
          previousMonthCount += 1;
          weekDay.isDisabled = !this.allowPastDateSelection;
        } else if (dayCount > totalDaysInMonth) {
          // Day belongs to next month
          weekDay.number = nextMonthCount;
          nextMonthCount += 1;
        } else {
          // // Day belongs to selected month
          todayISOString = `${year}-${month + 1}-${dayCount}`;
          weekDay.number = dayCount;
          weekDay.notOnThisMonth = false;
          dayCount += 1;
          if (this.today.isSame(todayISOString, 'day')) {
            weekDay.today = true;
          }
          if (this.selectedDate?.isSame(todayISOString, 'day')) {
            weekDay.selected = true;
          }
          if (dayjs(todayISOString).isBefore(this.today, 'day') && !this.allowPastDateSelection) {
            weekDay.isDisabled = true;
          }
        }
        weekDays[dayOfWeek] = weekDay;
      }
      monthLayout[week] = weekDays;
    }
    this.monthLayout = monthLayout;
  }

  generateDayClasses(day: Day) {
    const classes = [];
    if (day.notOnThisMonth || day.isDisabled) {
      classes.push('week-layout__day--greyed');
    }
    if (day.today) {
      classes.push('week-layout__day--today');
    }
    if (day.selected) {
      classes.push('week-layout__day--selected');
    }
    return classes;
  }
}
