import { render, staticRenderFns } from "./MDEmptyContent.vue?vue&type=template&id=60961794&"
import script from "./MDEmptyContent.vue?vue&type=script&lang=ts&"
export * from "./MDEmptyContent.vue?vue&type=script&lang=ts&"
import style0 from "./MDEmptyContent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-2ce6b8446c/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-eec86deeea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports