
















import { Component, Prop, Vue } from 'vue-property-decorator';

import MDProgressSpinner from '@minddocdev/lib-components/components/MDProgressSpinner.vue';

import { checkIsAppAcquisition } from '../../../lib-web/src/browser/url.utils';

/**
 * Default MindDoc button.
 */
@Component({
  components: { MDProgressSpinner },
})
export default class MDButton extends Vue {
  @Prop({ required: false, default: false })
  loading!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  get isAppAcquisition() {
    return checkIsAppAcquisition(this.$route);
  }
}
