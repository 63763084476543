






























import { Component, Prop, Vue } from 'vue-property-decorator';

import ClickOutside from '@minddocdev/lib-components/directives/click-outside';

type Align = 'left' | 'center' | 'right';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class MDDropdownMenu extends Vue {
  /**
   * Possible values: 'left' | 'center' | 'right'
   */
  @Prop({ required: false, type: String, default: 'right' })
  align!: Align;

  show = false;

  async toggle() {
    this.show = !this.show;
    if (this.show) {
      // We need to wait to the element to be rendered to get it's actual width
      await this.$nextTick();
      this.alignContent();
    }
  }

  close() {
    this.show = false;
  }

  alignContent() {
    const {
      offsetTop,
      offsetLeft,
      offsetHeight,
      clientWidth: toggleWith,
    } = this.$refs.toggle as HTMLElement;
    const { style, clientWidth: contentWidth } = this.$refs.content as HTMLElement;

    style.top = `${offsetTop + offsetHeight}px`;
    if (this.align === 'right') {
      style.left = `${offsetLeft - (contentWidth - toggleWith)}px`;
    } else if (this.align === 'left') {
      style.left = `${offsetLeft}px`;
    } else {
      style.left = `${offsetLeft - (contentWidth - toggleWith) / 2}px`;
    }
  }
}
