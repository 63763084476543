










































import { Component, Prop, Vue } from 'vue-property-decorator';

import MDFormInput from '@minddocdev/lib-components/components/MDFormInput.vue';

@Component({
  components: { MDFormInput },
})
export default class MDInputText extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  invalid!: boolean;
  @Prop({ type: String, required: false, default: '' })
  invalidText!: string;
  @Prop({ type: String, required: false, default: '' })
  note!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconSrc!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconAlt!: string;
  @Prop({ type: String, required: false, default: '' })
  placeholder!: string;
  @Prop({ type: [String, Date, Object], required: false, default: '' })
  value!: string;
  @Prop({ type: String, required: false, default: 'off' })
  autocomplete!: string;
  @Prop({ type: String, required: false, default: 'text' })
  type!: string;
  @Prop({ type: String, required: false })
  label!: string;
  @Prop({ type: String, required: false })
  name!: string;
  @Prop({ type: String, required: false, default: '' })
  min!: string;
}
