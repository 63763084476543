/**
 * Lazy loading of translations
 * https://kazupon.github.io/vue-i18n/en/lazy-loading.html
 * We could add our global messages as well,
 * though we manage them in single page components
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import safeI18n from '@minddocdev/lib-web/plugins/SafeI18n';
import messages from '@minddocdev/ui-accounts/lang';

import axios from 'axios';

Vue.use(VueI18n);
Vue.use(safeI18n);

/*
 * The project default language, retrieved from the browser.
 * navigator.language comes in BCP47 format: http://www.ietf.org/rfc/bcp/bcp47.txt
 * */
export const DEFAULT_LANGUAGE = navigator.language.substring(0, 2).toLowerCase();

/* The i18n object to be loaded in each Vue component */
const i18n = new VueI18n({
  messages: messages as any,
  locale: DEFAULT_LANGUAGE, // set current locale
  fallbackLocale: 'en', // if locale does not exist, will show 'English'
  silentTranslationWarn: true, // suppress warnings output when localization fails
});
axios.defaults.headers.common['Accept-Language'] = DEFAULT_LANGUAGE;

/**
 * Sets the language in the i18n object, http headers for API calls and document.
 * @param lang - The language string.
 */
function setI18nLanguage(lang: Language) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')!.setAttribute('lang', lang);
  return lang;
}

/**
 * Change current i18 language if provided differs.
 * @param lang - The provided i18 language.
 */
export function loadLanguage(lang: Language) {
  if (i18n.locale !== lang) {
    return setI18nLanguage(lang);
  }
  return lang;
}

export default i18n;
