import { Search } from '@minddocdev/lib-common/search';
import { authTherapyApi, authUsersApi } from '@minddocdev/lib-web/api/core';

import { AxiosError, AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const DEFAULT_SEARCH: Search.SearchParams = {
  comparator: 'OR',
  pagination: { page: 1, perPage: 10 },
};

/**
 * Return api param in the form of <field>.<sortDirection>
 * @returns empty string if param sorting not defined, otherwise <field>.<sortDirection>
 */
export function sortingToParam(sorting?: Search.Sort[]): string {
  if (!sorting) return '';
  return sorting.map((s) => `${s.sortBy}.${s.orderBy}`).join(',');
}

/**
 * Return api request param in form of semicolon separated filters (<filter>=<comparator>:<value>;)
 * @returns empty string if param filters is not defined or all filters has no value.
 * Otherwise (<filter>=<comparator>:<value>)
 */
export function filtersToParam(filters?: Search.Filter[]): string {
  if (!filters) return '';

  const filtersValues: string[] = [];
  filters.forEach(({ field, operator, value }) => {
    if (!value || !value.length) return;
    let parsedValue: any;
    switch (operator) {
      case 'in':
        parsedValue = value.join(',');
        break;
      case 'in_object_array':
        parsedValue = `{${value.join(',')}}`;
        break;
      default:
        parsedValue = value;
        break;
    }
    filtersValues.push(`${field}=${operator}:${parsedValue}`);
  });
  return filtersValues.length ? `(${filtersValues.join(';')})` : '';
}

/**
 * Return api request param in form of semicolon separated filters extra (<extra>=<value>;)
 */
export function filterExtraToParam(extras?: Search.FilterExtras): string {
  if (!extras) return '';
  const { accent, fuzzy } = extras;
  const accentParam = accent.insensitive ? 'accent=insensitive' : '';
  const fuzzyParam = fuzzy.enabled ? `fuzzy=${fuzzy.sensitivity || ''}` : '';
  if (!fuzzyParam && !accentParam) return '';
  return `(${accentParam};${fuzzyParam})`;
}

/**
 * Mutates the provided axios config by setting the Authorization header.
 *
 * @param config the axios config for the request.
 * @param authenticationTokenFn gets the authentication token
 * @returns the mutated axios config.
 */
export function setAuthorizationHeader(
  config: InternalAxiosRequestConfig,
  authenticationTokenFn: () => string | undefined,
): InternalAxiosRequestConfig {
  if (!config.headers) config.headers = new AxiosHeaders();
  config.headers.Authorization = `Bearer ${authenticationTokenFn() || ''}`;
  return config;
}

/**
 * Creates interceptors to inject headers and refresh mechanism in authenticated axios instances.
 * @param authenticationTokenFn
 * @param refreshTokenFn
 */
export function createInterceptors(
  authenticationTokenFn: () => string | undefined,
  refreshTokenFn: (error: AxiosError) => Promise<any>,
) {
  [authUsersApi, authTherapyApi].forEach((instance: AxiosInstance) => {
    createAuthRefreshInterceptor(instance, refreshTokenFn);
    instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      return setAuthorizationHeader(config, authenticationTokenFn);
    });
  });
}
