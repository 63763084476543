import { FilterOperator, Search } from '@minddocdev/lib-common/search';
import { Diagnosis } from '@minddocdev/lib-common/therapy';
import { Community, Role } from '@minddocdev/lib-common/users';
import { authTherapyApi, authUsersApi, usersApi } from '@minddocdev/lib-web/api/core';
import { filtersToParam, sortingToParam } from '@minddocdev/lib-web/api/helpers';
import {
  B2BCampaignReq,
  B2BCampaignsResDto,
  Company,
  EditB2BCampaignReq,
  InitialConsultationAllSection,
  LinkedPatientQueryParams,
  LinkedPractitionerQueryParams,
  OTPExpiredError,
  PatientProgressInfo,
  Policy,
  RegisterCompanyReq,
} from '@minddocdev/lib-web/api/types';
import { isOTPExpired } from '@minddocdev/lib-web/utils/auth.utils';

/**
 * Users API under api.minddoc/users
 * https://rest-users.sso.minddoc.com/#/
 */

/**
 * /ADMIN
 */

export async function deactivatePatient(uuid: string): Promise<void> {
  await authUsersApi.post(`/web/patients/${uuid}/deactivation`);
}

export async function deactivatePractitioner(uuid: string): Promise<void> {
  await authUsersApi.post(`/web/practitioners/${uuid}/deactivation`);
}

export async function getAdminProfile(): Promise<RestUsers.ProfileResDto> {
  return (await authUsersApi.get('/web/admin/profile')).data;
}

export async function patchAdminProfile(
  profile: RestUsers.ProfileReqDto,
): Promise<RestUsers.ProfileResDto> {
  return (await authUsersApi.patch('/web/admin/profile', profile)).data;
}

/**
 * Register a new admin user.
 */
export async function postAdminProfile(
  profile: RestUsers.RegisterAdminReqDto,
): Promise<RestUsers.ProfileResDto> {
  return (await authUsersApi.post('/web/admins', profile)).data;
}

export async function getAdminProfiles(
  search?: Search.SearchParams,
): Promise<RestUsers.ProfileResDto[]> {
  const { comparator, pagination, sorting, filters } = { ...search };
  return (
    await authUsersApi.get('/web/admins/profiles', {
      params: {
        comparator: comparator ?? 'OR',
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sortingToParam(sorting),
        filter: filtersToParam(filters),
      },
    })
  ).data;
}

export async function patchAdminRoles(uuid: string, roles: Role[]): Promise<void> {
  await authUsersApi.patch('/web/admins/roles', {
    uuid,
    roles,
  });
}

/**
 * /AUTH
 */

export async function postLogin(
  email: string,
  password: string,
  resend: boolean,
): Promise<RestUsers.JwtPairResDto> {
  return (
    await usersApi.post('/auth/login', {
      email,
      password,
      resend,
    })
  ).data;
}

export async function postLogout(accessToken: string): Promise<void> {
  await usersApi.post('/auth/logout', undefined, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function postOptIn(accessToken: string): Promise<RestUsers.JwtPairResDto> {
  return (
    await usersApi.post('/auth/opt-in', undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).data;
}

export async function postPasswordChange(
  oldPassword: string,
  newPassword: string,
): Promise<RestUsers.JwtPairResDto> {
  return (
    await authUsersApi.post('/auth/password/change', {
      oldPassword,
      newPassword,
    })
  ).data;
}

export async function postResetPassword(
  otp: string,
  newPassword: string,
): Promise<RestUsers.JwtPairResDto> {
  if (isOTPExpired(otp)) {
    throw new OTPExpiredError();
  }
  return (
    await usersApi.post('/auth/password/reset', {
      otp: decodeURIComponent(otp),
      password: newPassword,
    })
  ).data;
}

/**
 * Extend the users session by creating new access and refresh tokens.
 */
export async function postToken(
  refreshToken: string,
  updateToken = false,
): Promise<RestUsers.JwtPairResDto> {
  let body = undefined;
  if (updateToken) {
    body = {
      isUpdated: true,
    };
  }

  return (
    await usersApi.post('/auth/token', body, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    })
  ).data;
}

/**
 * /EMAILS
 */

/**
 * Sends an announcement email using a template to all users with the specified user roles.
 */
export async function postEmailsAnnouncement(
  emails: string[],
  roles: Role[],
  template: string,
): Promise<void> {
  await authUsersApi.post('/emails/announcement', { emails, roles, template });
}

/**
 * Sends an email to the provided user's email address with a password reset link.
 */
export async function postEmailForgotPassword(email: string, resend: boolean): Promise<void> {
  await authUsersApi.post('/emails/forgot-password', { email, resend });
}

/**
 * /insurances
 */

/**
 * Lists the insurance companies that have online therapy contracts with MindDoc.
 */
export async function getInsurances(advertised?: boolean): Promise<RestUsers.InsuranceResDto[]> {
  return (await authUsersApi.get('/insurances', { params: { advertised } })).data;
}

/**
 * /PATIENTS
 */

/**
 * Retrieve the authenticated patient profile.
 */
export async function getPatientProfile(): Promise<RestUsers.PatientProfileResDto> {
  return (await authUsersApi.get('/patient/profile')).data;
}

export async function patchPatientProfile(
  profile: Partial<RestUsers.PatientProfileReqDto>,
): Promise<RestUsers.PatientProfileResDto> {
  return (await authUsersApi.patch('/patient/profile', profile)).data;
}

/**
 * Complete the onboarding process using the invite JWT to identify the patient
 */
export async function postPatient(
  profile: RestUsers.OnboardPatientReqDto,
): Promise<RestUsers.JwtPairResDto> {
  return (await authUsersApi.post('/web/patient', profile)).data;
}

/**
 * Registers a diagnostic patient and enables their account
 */
export async function postPatientDiagnostic(
  profile: RestUsers.RegisterDiagnosticPatientReqDto,
): Promise<void> {
  await authUsersApi.post('/web/patients/diagnostic', profile);
}
/**
 * Registers a premium app user
 */
export async function postPremiumAppRegistration(
  profile: RestUsers.RegisterLoginReqDto,
): Promise<void> {
  await authUsersApi.post('/premiumapp/register', profile);
}

/**
 * Invites a new patient to the platform
 */
export async function postPatientsInvitation(
  payload: RestUsers.InvitePatientReqDto,
): Promise<RestUsers.PatientProfileResDto> {
  return (await authUsersApi.post('/web/patients/invitation', payload)).data;
}
export async function postUnguidedClientPromotion(
  profile: RestUsers.DiagnosticSessionPromotionReqDto,
  accessToken: string,
): Promise<RestUsers.JwtPairResDto> {
  return (
    await authUsersApi.post('/web/patient/diagnostic/promotion', profile, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).data;
}

export async function getPatientConnectedPractitioners(
  patientUuid: string,
): Promise<RestUsers.PractitionerProfileResDto[]> {
  return (await authUsersApi.get(`/web/patients/${patientUuid}/connected-practitioners`)).data;
}

export async function postPatientOnlineTherapyPromotion(
  patientUuid: string,
  diagnosis: Diagnosis,
  orbisId: string,
): Promise<void> {
  await authUsersApi.post(`/web/patients/${patientUuid}/online-therapy/promotion`, {
    diagnosis,
    orbisId,
  });
}

export async function deletePatient(patientUuid: string): Promise<void> {
  await authUsersApi.delete(`/web/patients/${patientUuid}`);
}

export async function patchPatientNotes(patientUuid: string, notes: string): Promise<void> {
  await authUsersApi.patch(`/web/patients/${patientUuid}/note`, { notes });
}

export async function getPatientsProfile(
  patientUuid: string,
  queryParams?: LinkedPractitionerQueryParams,
): Promise<RestUsers.PatientProfileResDto> {
  const { connected, includeConnectedPractitioners, linked } = { ...queryParams };
  return (
    await authUsersApi.get(`/web/patients/profiles/${patientUuid}`, {
      params: {
        connected,
        includeConnectedPractitioners,
        linked,
      },
    })
  ).data;
}

export async function getPatientsProfiles(
  search?: Search.SearchParams,
  queryParams?: LinkedPractitionerQueryParams,
): Promise<RestUsers.PatientProfileResDto[]> {
  const { comparator, pagination, sorting, filters } = { ...search };
  const { includeConnectedPractitioners, connected, linked } = { ...queryParams };
  return (
    await authUsersApi.get('/web/patients/profiles', {
      params: {
        includeConnectedPractitioners,
        connected,
        linked,
        comparator: comparator ?? 'OR',
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sortingToParam(sorting),
        filter: filtersToParam(filters),
      },
    })
  ).data;
}

export async function getPatientProfilesEligibleForTherapy(
  query: string,
): Promise<RestUsers.PatientProfileResDto[]> {
  const baseUrl = '/web/patients/profiles/eligible/therapy-connections';
  return (
    await authUsersApi.get(baseUrl, {
      params: {
        query: encodeURIComponent(query),
      },
    })
  ).data;
}

export async function patchPatientRoles(
  uuid: string,
  roles: Role[],
): Promise<RestUsers.PatientProfileResDto> {
  return (
    await authUsersApi.patch('/web/patients/roles', {
      uuid,
      roles,
    })
  ).data;
}
export async function postExcludePatient(uuid: string): Promise<RestUsers.PatientProfileResDto> {
  return (await authUsersApi.post(`/web/patients/${uuid}/online-therapy/exclusion`)).data;
}

export async function patchPatientActor(
  patientUuid: string,
  payload: RestUsers.UpdatePatientActorReqDto,
): Promise<RestUsers.PatientProfileResDto> {
  return (await authUsersApi.patch(`/web/patients/${patientUuid}/actor`, payload)).data;
}

/**
 * /PRACTITIONERS
 */

/**
 * Retrieve practitioners.
 */

export async function getPractitionersBySearchKey(
  searchKey?: string,
): Promise<RestUsers.PractitionerResDTO[]> {
  const { data: { practitioners } = { practitioners: [] } } = await authUsersApi.get(
    '/web/practitioners/search',
    {
      params: {
        searchKey,
      },
    },
  );

  return practitioners;
}

/**
 * Retrieve the current authenticated practitioner profile.
 */
export async function getPractitionerProfile(): Promise<RestUsers.PractitionerProfileResDto> {
  return (await authUsersApi.get('/web/practitioner/profile')).data;
}

/**
 * Retrieve the current authenticated practitioner profile.
 */
export async function getPractitionerProfiles(): Promise<RestUsers.PractitionerProfileResDto[]> {
  return (await authUsersApi.get('/web/practitioners/profiles?includeConnectedPatients=true')).data;
}

/**
 * Retrieve the current authenticated practitioner profile.
 */
export async function getPractitionerProfileByUuid(
  uuid: string,
  flags?: LinkedPatientQueryParams,
): Promise<RestUsers.PractitionerProfileResDto> {
  return (
    await authUsersApi.get(`/web/practitioners/profiles/${uuid}`, {
      params: flags,
    })
  ).data;
}

export async function patchPractitionerProfile(
  profile: Partial<RestUsers.PractitionerProfileResDto>,
): Promise<RestUsers.PractitionerProfileResDto> {
  return (await authUsersApi.patch('/web/practitioner/profile', profile)).data;
}

/**
 * Complete the onboarding process using the invite JWT to identify the practitioner
 */
export async function postPractitioner(
  profile: RestUsers.OnboardPractitionerReqDto,
): Promise<RestUsers.JwtPairResDto> {
  return (await authUsersApi.post('/web/practitioner', profile)).data;
}

/**
 * Creates a therapy connection between the provided patient uuid and the authenticated practitioner.
 */
export async function postPractitionerTherapies(
  patientUuid: string,
): Promise<RestUsers.TherapyResDto> {
  return (await authUsersApi.post('/web/practitioner/therapies', { patientUuid })).data;
}

/**
 * Removes a therapy connection between the provided patient uuid and the authenticated practitioner.
 */
export async function deletePractitionerTherapyPatient(patientUuid: string): Promise<void> {
  await authUsersApi.delete(`/web/practitioner/therapies/patients/${patientUuid}`);
}

/**
 * Invites a new practitioner to the platform
 */
export async function postPractitionersInvitation(
  payload: RestUsers.InvitePractitionerReqDto,
): Promise<RestUsers.PractitionerProfileResDto> {
  return (await authUsersApi.post('/web/practitioners/invitation', payload)).data;
}

export async function deletePractitioner(practitionerUuid: string): Promise<void> {
  await authUsersApi.delete(`/web/practitioners/${practitionerUuid}`);
}

export async function getPractitionersProfile(
  practitionerUuid: string,
): Promise<RestUsers.PractitionerProfileResDto> {
  return (await authUsersApi.get(`/web/practitioners/profiles/${practitionerUuid}`)).data;
}

export async function getPractitionersProfiles(
  search?: Search.SearchParams,
  queryParams?: LinkedPatientQueryParams,
): Promise<RestUsers.PractitionerProfileResDto[]> {
  const { comparator, pagination, sorting, filters } = { ...search };
  const { connected } = { ...queryParams };
  return (
    await authUsersApi.get('/web/practitioners/profiles', {
      params: {
        connected,
        comparator: comparator ?? 'OR',
        page: pagination?.page,
        perPage: pagination?.perPage,
        sort: sortingToParam(sorting),
        filter: filtersToParam(filters),
        ...queryParams,
      },
    })
  ).data;
}

/**
 * Retrieve the practitioner roles.
 */
export async function getPractitionerRoles(): Promise<Role[]> {
  return (await authUsersApi.get('/web/practitioners/roles')).data;
}

export async function patchPractitionerRoles(uuid: string, roles: Role[]): Promise<void> {
  await authUsersApi.patch('/web/practitioners/roles', { uuid, roles });
}

export async function getPractitionersByEmailOrName(
  searchText: string,
): Promise<RestUsers.PractitionerProfileResDto[]> {
  const value = [`${searchText}%`];
  return await getPractitionersProfiles({
    comparator: 'OR',
    // TODO: is 20 fine?
    pagination: { page: 1, perPage: 20 },
    filters: [
      {
        value,
        field: 'firstName',
        operator: FilterOperator.ILike,
      },
      {
        value,
        field: 'lastName',
        operator: FilterOperator.ILike,
      },
      {
        value,
        field: 'email',
        operator: FilterOperator.ILike,
      },
    ],
    sorting: [
      {
        orderBy: 'asc',
        sortBy: 'firstName',
      },
    ],
  });
}

/**
 * Common profile endpoints
 */

export async function postProfileAvatar(file: File): Promise<string> {
  const formData = new FormData();
  formData.set('avatar', file);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return (await authUsersApi.post('/profile/avatar', formData, config)).data.profilePicture;
}

export async function deleteProfileAvatar(): Promise<void> {
  await authUsersApi.delete('/profile/avatar');
}

export async function postProfileAvatarV2(file: File): Promise<string> {
  const formData = new FormData();
  formData.set('avatar', file);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return (await authUsersApi.post('/profile/v2/avatar', formData, config)).data.profilePicture;
}

export async function deleteProfileAvatarV2(): Promise<void> {
  await authUsersApi.delete('/profile/V2/avatar');
}

/**
 * Verifies an user's email address and activates their account
 */
export async function postProfileEmail(
  otp: string,
  bookableSlotUuid?: string,
): Promise<RestUsers.JwtPairResDto> {
  if (isOTPExpired(otp)) {
    throw new OTPExpiredError();
  }
  return (
    await authUsersApi.post('/profile/email', { otp: decodeURIComponent(otp), bookableSlotUuid })
  ).data;
}

/**
 * Initiate the email confirmation process by generating an otp and emailing the user.
 */
export async function postProfileEmailVerification(
  email: string,
  bookableSlotUuid: string,
  resend: boolean,
): Promise<void> {
  await authUsersApi.post('/profile/email/verification', { email, bookableSlotUuid, resend });
}

/**
 * Verify user's phone number ownership.
 */
export async function postProfilePhoneNumber(otp: string): Promise<void> {
  if (isOTPExpired(otp)) {
    throw new OTPExpiredError();
  }
  await authUsersApi.post('/profile/phone-number', { otp });
}

/**
 * Initiate the phone number confirmation process by generating an otp and messaging the user.
 */
export async function postProfilePhoneNumberVerification(
  phoneNumber: string,
  userUuid: string,
  resend: boolean,
): Promise<void> {
  await authUsersApi.post('/profile/phone-number/verification', { phoneNumber, userUuid, resend });
}

/**
 * /settings
 */

export async function getSettingsNotification(): Promise<RestUsers.NotificationSettingsResDto> {
  return (await authUsersApi.get('/settings/notification')).data;
}

export async function putSettingsNotification(
  settings: RestUsers.UpdateNotificationSettingsReqDto,
): Promise<void> {
  await authUsersApi.put('/settings/notification', settings);
}

/**
 * /therapies
 */

export async function deleteTherapy(therapyUuid: string): Promise<void> {
  await authUsersApi.delete(`/web/therapies/${therapyUuid}`);
}

export async function postTherapy(
  patientUuid: string,
  practitionerUuid: string,
): Promise<RestUsers.TherapyResDto> {
  return (await authUsersApi.post('/web/therapies', { patientUuid, practitionerUuid })).data;
}

export async function patchTherapyUnreadMessages(
  therapyUuid: string,
  payload: RestUsers.UpdateTherapyUnreadMessagesReqDto,
): Promise<RestUsers.TherapyResDto> {
  return (await authUsersApi.patch(`/web/therapies/${therapyUuid}/unread-messages`, payload)).data;
}

export async function patchCommunity(community: Community, userUuids: string[]): Promise<void> {
  await authUsersApi.patch('/web/community', { community, userUuids });
}

/**
 * Gets a list of experimental features that the authenticated user can access.
 */
export async function getFeatures(): Promise<Record<string, boolean>> {
  return (await authUsersApi.get('/web/features')).data;
}

/**
 * Verifies an invitation otp.
 */
export async function postInvitation(otp: string): Promise<RestUsers.JwtAccessTokenResDto> {
  if (isOTPExpired(otp)) {
    throw new OTPExpiredError();
  }
  return (await authUsersApi.post('/web/invitation', { otp: decodeURIComponent(otp) })).data;
}
export async function postInitiateEmailChange(newEmail: string): Promise<any> {
  return (
    await authUsersApi.post('/profile/change-email/verification', {
      newEmail,
      resend: true,
    })
  ).data;
}

/**
 * Invite unregistered patients to videochat therapy.
 */

export async function postInviteUnregisteredPatient(
  body: RestTherapy.CounselingReqDTO,
): Promise<number> {
  return (await authTherapyApi.post('/web/video-session/invite', body)).status;
}

export async function postConfirmEmailChange(
  password: string,
  otp: string,
): Promise<RestUsers.JwtPairResDto> {
  return (
    await usersApi.post('/profile/change-email', {
      password,
      otp,
    })
  ).data;
}

export async function endPatientTherapy(
  patientUuid: string,
  payload: RestUsers.EndPatientTherapyReqDto,
): Promise<RestUsers.PatientProfileResDto> {
  return (
    await authUsersApi.post(`/web/patients/${patientUuid}/online-therapy/conclusion`, payload)
  ).data;
}

/**
 * Get patient consultation information.
 */
export async function getFirstConsultationSections(
  patientUuid: string,
  practitionerUuid: string,
): Promise<InitialConsultationAllSection> {
  return (
    await authTherapyApi.get(
      `/web/digitalization/all-sections?patientUuid=${patientUuid}&practitionerUuid=${practitionerUuid}`,
    )
  ).data;
}

/**
 * Get patient first consultation information.
 */
export async function getUserFirstConsultationInfo(
  patientUuid: string,
  practitionerUuid: string,
): Promise<PatientProgressInfo> {
  return (
    await authTherapyApi.get(
      `/web/digitalization/first-consultation?patientUuid=${patientUuid}&practitionerUuid=${practitionerUuid}`,
    )
  ).data;
}

/**
 * Get section data.
 */
export async function getSectionData(sectionUuid: string, patientUuid: string): Promise<any> {
  return (
    await authTherapyApi.get(
      `/web/digitalization/section/${sectionUuid}?patientUuid=${patientUuid}`,
    )
  ).data;
}

/**
 * Save section data.
 */
export async function saveSectionData(payload: any): Promise<any> {
  return (await authTherapyApi.post(`/web/digitalization/submit`, payload)).data;
}

/**
 * Get inclusion/exlusion status data.
 */
export async function getInclusionExlusionStatusData(
  patientUuid: string,
  practitionerUuid: string,
): Promise<any> {
  return (
    await authTherapyApi.get(
      `/web/digitalization/inclusion-criteria?patientUuid=${patientUuid}&practitionerUuid=${practitionerUuid}`,
    )
  ).data;
}

/**
 * Submit final report.
 */
export async function saveFinalReport(payload: any): Promise<any> {
  return (await authTherapyApi.post(`/web/digitalization/report/submit`, payload)).data;
}

/**
 * Get report overview.
 */
export async function getOverviewReportData(
  patientUuid: string,
  practitionerUuid: string,
): Promise<any> {
  return (
    await authTherapyApi.get(
      `/web/digitalization/report/overview?patientUuid=${patientUuid}&practitionerUuid=${practitionerUuid}`,
    )
  ).data;
}

/**
 * Get latest therapist uuid for report.
 */
export async function getTherapistUuidForReportData(patientUuid: string): Promise<any> {
  return (await authTherapyApi.get(`/web/digitalization/report/therapist/${patientUuid}`)).data;
}

/**
 * Get signed insurance.
 */
export async function getSignedInsurance(uuid: string): Promise<any> {
  return (await usersApi.get(`/policies/signed/latest?consentUuid=${uuid}`)).data;
}

/**
 * Get latest insurance.
 */
export async function getLatestPolicies(policies: string[]): Promise<Policy[]> {
  return (
    await usersApi.get(`/policies/latest?appType=therapyPlatform&policyNames=${policies.join()}`)
  ).data;
}

/**
 * submit signed policies.
 */
export async function submitSignedPolicies(payload: any): Promise<any> {
  return (await authUsersApi.post(`/policies`, payload)).data;
}

/**
 * submit signed policies with identity.
 */
export async function updateSignedPolicies(payload: any): Promise<any> {
  return (await authUsersApi.put(`/policies`, payload)).data;
}

/**
 * Get campaign list.
 */
export async function getCampaignList(
  page: number,
  perPage: number,
  sort: string,
): Promise<{ b2bCampaigns: B2BCampaignsResDto[] }> {
  return (await authUsersApi.get(`/web/b2bcampaign?page=${page}&perPage=${perPage}&sort=${sort}`))
    .data;
}

/**
 * Get campaign detail.
 */
export async function getCampaignDetailByUuid(
  id: string,
): Promise<{ b2bCampaigns: B2BCampaignsResDto[] }> {
  return (await authUsersApi.get(`/web/b2bcampaign/details?uuid=${id}`)).data;
}

/**
 * Get company list for campaign.
 */
export async function getCompanyList(name: string): Promise<{ companies: Company[] }> {
  return (await authUsersApi.get(`/web/b2bcampaign/company?name=${name}`)).data;
}

/**
 * add new company.
 */
export async function addCompany(payload: RegisterCompanyReq): Promise<Company> {
  return (await authUsersApi.post(`/web/b2bcampaign/company`, payload)).data;
}

/**
 * update company.
 */
export async function updateCompany(payload: Company): Promise<Company> {
  return (await authUsersApi.patch(`/web/b2bcampaign/company`, payload)).data;
}

/**
 * save campaign.
 */
export async function saveCampaign(
  payload: B2BCampaignReq,
): Promise<{ b2bCampaigns: B2BCampaignsResDto[] }> {
  return (await authUsersApi.post(`/web/b2bcampaign`, payload)).data;
}

/**
 * update campaign detail.
 */
export async function updateCampaign(
  payload: EditB2BCampaignReq,
): Promise<{ b2bCampaigns: B2BCampaignsResDto[] }> {
  return (await authUsersApi.patch(`/web/b2bcampaign`, payload)).data;
}

/**
 * Redeem counselling voucher
 */
export async function redeemCounsellingVoucher(code: string) {
  return (await authUsersApi.post(`/web/b2bcampaign/redeem/counselling`, { code })).data;
}

/**
 * Redeem premium app voucher
 */
export async function redeemPremiumAppVoucher(code: string) {
  return (await authUsersApi.post(`/web/b2bcampaign/redeem/premium-app`, { code })).data;
}

export async function fetchVoucherRedemptionHistory(): Promise<
  RestUsers.VoucherRedemptionHistoryResDto[]
> {
  return (await authUsersApi.get(`/web/b2bcampaign/redemptions`)).data;
}

/**
 * Get remaining sessions
 */
export async function fetchRemainingSessions(
  uuid: string,
): Promise<RestUsers.RemainingSessionsResDto> {
  return (await authUsersApi.get(`/web/b2bcampaign/${uuid}/redemptions`)).data;
}
