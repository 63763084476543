import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const REFRESH_TOKEN_REGEX = /(?:^| )RefreshToken=([^;]+)/;
const ACCESS_TOKEN_REGEX = /(?:^| )AccessToken=([^;]+)/;

/**
 * Extracts Access and Refresh tokens from document.cookie
 */
export function extractTokensFromCookies(cookies: string): {
  accessToken: string | undefined;
  refreshToken: string | undefined;
} {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const [, accessToken] = cookies.match(ACCESS_TOKEN_REGEX) || [];
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const [, refreshToken] = cookies.match(REFRESH_TOKEN_REGEX) || [];
  return {
    accessToken,
    refreshToken,
  };
}

/**
 * Check if the jwt token hasn't expired.
 * @param decodedToken - The decoded jwt token to be checked
 * @returns true if the token hasn't expired yet, false otherwise
 */
export function isValidJwt(decodedToken?: any): boolean {
  if (!decodedToken) {
    return false;
  }
  const { exp } = decodedToken;
  return dayjs().utc().isBefore(dayjs.unix(exp).utc());
}

export function isOTPExpired(otp: string): boolean {
  try {
    const decodedOtp = JSON.parse(atob(otp));
    // NOTE: `exp` field is expressed in milliseconds
    return dayjs().utc().isAfter(dayjs(decodedOtp.exp).utc());
  } catch {
    return true;
  }
}
