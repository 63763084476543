import { captureException } from '@sentry/vue';

export interface LinkedPatientQueryParams {
  includeConnectedPatients?: boolean;
  connected?: boolean;
  linked?: boolean;
}

export interface LinkedPractitionerQueryParams {
  includeConnectedPractitioners?: boolean;
  connected?: boolean;
  linked?: boolean;
}

export class OTPExpiredError extends Error {
  constructor() {
    super('OTP is expired');
    this.name = 'OTPExpiredError';
    captureException(this, { level: 'warning' });
  }
}

export interface InitialConsultationAllSection {
  sections: InitialConsultationSection[];
}

export interface InitialConsultationSection {
  sectionUuid: string;
  title: string;
  icon: string | null;
  sectionKey: string;
  createdAt: string;
  updatedAt: string;
  hasSubSections: boolean;
  sequence: number;
  subSections: SubSectionsList[];
  status: string;
  isMandatoryComponentFilled: boolean;
}

export interface SubSectionsList {
  subSectionUuid: string;
  title: string;
  sequence: number;
  status: string;
}

export interface PatientProgressInfo {
  status: string;
  firstConsultationDate: string | undefined;
  reportGenerationDate?: string;
  patientInclusionStatus?: string;
}

export enum B2BCampaignsType {
  PayPerUse = 'pay-per-use',
  Packages = 'packages',
  Flatrate = 'flatrate',
}

export interface CreateB2BCampaignsReqDto {
  b2bCampaignName: string;
  startDate: string;
  endDate: string;
  campaignType: B2BCampaignsType;
  prefix: string;
  numOfVoucherCode?: number;
  targetGroup: string;
  lengthOfSession: string;
  maxUsageCount: number;
  numOfSessionsPerVoucher: number;
  company: RegisterCompanyReq;
  basicFee?: string;
  pricePerSession?: string;
  notes?: string;
  isPremiumAccess: boolean;
}

export interface B2BCampaignsResDto {
  uuid: string;
  b2bCampaignName: string;
  startDate: string;
  endDate: string;
  campaignType: string;
  prefix: string;
  numOfVoucherCode: number;
  targetGroup: CampaignTargetGroup;
  lengthOfSession: string;
  maxUsageCount: number;
  numOfSessionsPerVoucher: number;
  company: Company;
  createdAt: string;
  createdBy: Uuid;
  timezoneName: string;
  basicFee?: string;
  pricePerSession?: number;
  notes?: string;
  isPremiumAccess: boolean;
  subscriptionDuration: number;
}

export interface EditB2BCampaignReq {
  uuid: string;
  campaignDetails: B2BCampaignReq;
}

export interface B2BCampaignReq {
  b2bCampaignName?: string;
  startDate?: string;
  endDate?: string;
  campaignType?: string;
  lengthOfSession?: string;
  targetGroup?: string;
  numOfVoucherCode?: number;
  prefix?: string;
  maxUsageCount?: number;
  numOfSessionsPerVoucher?: number;
  basicFee?: string;
  pricePerSession?: string;
  notes?: string;
  isPremiumAccess?: boolean;
}

export interface Company {
  id: number;
  name: string;
}

export interface RegisterCompanyReq {
  name: string;
}

export enum CampaignTargetGroup {
  B2BCounselling = 'B2BCounselling',
  B2BPremiumApp = 'B2BPremiumApp',
}

export interface Policy {
  uuid: string;
  name: string;
  version: string;
  appType: string;
  contentLink?: string;
}
