




































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDLogo extends Vue {
  /**
   * Shows company name and details.
   */
  @Prop({ type: Boolean, required: false, default: false })
  expanded!: boolean;
  /**
   * Set default width of the svg and the viewbox.
   */
  @Prop({ type: Number, required: false, default: 160 })
  width!: number;
  /**
   * Set default height of the svg and the viewbox.
   */
  @Prop({ type: Number, required: false, default: 31 })
  height!: number;

  @Prop({ type: String, required: false, default: '#1E3A63' })
  overhideTextColor1!: string;

  @Prop({ type: String, required: false, default: '#F9714B' })
  overhideTextColor2!: string;

  @Prop({ type: String, required: false, default: '#1E3A63' })
  overhideTextColor3!: string;

  get viewBox() {
    return `0 0 ${this.width} ${this.height}`;
  }
}
