import { VALID_PASSWORD_REGEX } from '@minddocdev/lib-common/utils/validators/regex';

import { zxcvbn } from '../utils/zxcvbn.utils';

export interface PasswordStrength {
  score: number;
  color: 'green' | 'yellow' | 'red';
  message: string;
}

/**
 * Calculate password score using zxcvbn library
 * NOTE: Validation strings can be find in @minddocdev/locale/Validation.yaml
 */
export function calculatePasswordStrength(password: string): PasswordStrength {
  const result: PasswordStrength = {
    color: 'red',
    message: 'inputs.required',
    score: 0,
  };
  if (password.length === 0) {
    return result;
  }
  switch (zxcvbn(password).score) {
    case 4:
      result.color = 'green';
      result.message = '';
      result.score = 100;
      break;
    case 3:
      result.color = 'green';
      result.message = '';
      result.score = 75;
      break;
    case 2:
      result.color = 'yellow';
      result.message = 'validation.password.scores.2';
      result.score = 50;
      break;
    case 1:
      result.color = 'red';
      result.message = 'validation.password.scores.1';
      result.score = 25;
      break;
    case 0:
    default:
      result.color = 'red';
      result.message = 'validation.password.scores.0';
      result.score = 10;
      break;
  }
  // Check for invalid chars
  if (!VALID_PASSWORD_REGEX.test(password) || password.length > 50) {
    result.color = 'red';
    result.message = 'validation.password.invalid';
    result.score = 50;
  }
  return result;
}
