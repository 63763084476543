










































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

import MDInputText from '@minddocdev/lib-components/components/MDInputText.vue';
import MDProgressBar from '@minddocdev/lib-components/components/MDProgressBar.vue';
import {
  calculatePasswordStrength,
  PasswordStrength,
} from '@minddocdev/lib-web/validation/password';

import { extend, ValidationProvider } from 'vee-validate';

@Component({
  components: { MDInputText, MDProgressBar },
})
export default class PasswordInput extends Vue {
  @Prop({ required: false, default: '' })
  label!: string;
  @Prop({ required: false, default: '' })
  note!: string;
  @Prop({ required: false, default: '' })
  placeholder!: string;
  @Prop({ required: false, default: 'required|password|max:255' })
  rules!: string;
  @Prop({ required: false, type: String, default: '' })
  value!: string;
  @Prop({ required: false, type: Boolean, default: true })
  checkStrength!: boolean;

  @Ref()
  readonly validationProvider!: InstanceType<typeof ValidationProvider>;

  show = false;
  focused = false;
  capsLock = false;
  passwordStrength: PasswordStrength = {
    color: 'red',
    score: 0,
    message: '',
  };

  created() {
    window.addEventListener('keyup', this.checkCapsLock);
    window.addEventListener('keydown', this.checkCapsLock);
    extend('password', {
      validate: () => this.passwordStrength.score > 50,
      message: () => this.$i18n.t(this.passwordStrength.message) as string,
    });
    if (!!this.value) {
      this.validatePassword();
    }
  }

  checkCapsLock(event: KeyboardEvent) {
    this.capsLock = this.focused && event.getModifierState && event.getModifierState('CapsLock');
  }

  @Watch('value')
  validatePassword() {
    if (!this.rules.includes('password')) return;

    this.passwordStrength = calculatePasswordStrength(this.value);
    this.validationProvider.validate();
  }

  destroyed() {
    window.removeEventListener('keyup', this.checkCapsLock);
    window.removeEventListener('keydown', this.checkCapsLock);
  }
}
