



















































import { Component, Prop, Vue } from 'vue-property-decorator';

import MDSelect from '@minddocdev/lib-components/components/MDSelect.vue';

const MAX_LABELS = 3;
const MIN_OPTIONS_SEARCH = 100;

@Component({
  components: { MDSelect },
  name: 'MDMultiSelect',
})
export default class MDMultiSelect extends Vue {
  /** Displays this label when all options are selected */
  @Prop({ required: true, type: String })
  allOptionsLabel!: string;
  @Prop({ required: false, default: 'text' })
  label!: string;
  @Prop({ required: false, default: 'value' })
  trackBy!: string;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: true, default: [] })
  options!: Record<string, unknown>[];
  @Prop({ required: true, default: [] })
  value!: any[];
  @Prop({ required: false, type: Number, default: MAX_LABELS })
  maxLabels!: number;
  @Prop({ required: false, type: Boolean, default: false })
  searchable!: boolean;
  @Prop({ required: false })
  maxSelection?: number;
  @Prop({ required: false })
  maxSelectionMessage?: string;
  /**
   * Define a minimum number of values to enable search functionality
   */
  @Prop({ required: false, type: Number, default: MIN_OPTIONS_SEARCH })
  minOptionsSearch!: number;

  get enableSearch(): boolean {
    return this.searchable && this.options.length > this.minOptionsSearch;
  }

  selectedOptionsLabel(values: Record<string, unknown>[]): string {
    let displayableOptions = [];
    if (values.length === 0) {
      return this.allOptionsLabel;
    }

    let label = '';
    const exceeds = values.length > this.maxLabels;
    displayableOptions = exceeds ? values.slice(0, this.maxLabels) : values;
    displayableOptions.forEach((option: Record<string, unknown>) => {
      label += `${option[this.label] ?? option}, `;
    });
    // Remove last comma and space
    label = label.slice(0, -2);
    if (exceeds) {
      label += ` +${values.length - this.maxLabels}`;
    }
    return label;
  }
}
