import { Cloudinary, Configuration } from 'cloudinary-core';

/* The global Cloudinary options */
const config: Configuration.Options = {
  cloud_name: 'minddoc',
  secure: true,
};

/* The global Cloudinary library instance */
const service = new Cloudinary(config);
export default service;

/* The face detection image cropping basic options */
const faceCropOption = {
  crop: 'thumb',
  gravity: 'face',
};

Object.freeze(faceCropOption);

export { faceCropOption };
