













































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { MDCloudinaryImage } from '@minddocdev/lib-components';
import MDLogo from '@minddocdev/lib-components/components/MDLogo.vue';
import { isTabletScreen } from '@minddocdev/lib-web/browser/url.utils';

@Component({ components: { MDLogo, MDCloudinaryImage } })
export default class Footer extends Vue {
  @Prop({ required: false, type: Boolean })
  showCrisisInfo!: boolean;

  openMenus = [''];

  subMenus = {
    partners: 'partners',
    mindDoc: 'minddoc',
    information: 'information',
    legal: 'legal',
    services: 'services',
  };

  isMobile = !isTabletScreen();

  toggleSubmenu(submenu: string) {
    const isAlreadyOpen = this.openMenus.some((sub: string) => sub === submenu);

    if (isAlreadyOpen) {
      const newArray = this.openMenus.filter((sub: string) => sub !== submenu);

      this.openMenus = newArray as string[];
    } else {
      this.openMenus = [...this.openMenus, submenu] as string[];
    }
  }

  isSubmenuOpen(submenu: string) {
    return (
      !this.isMobile || (this.isMobile && this.openMenus.some((sub: string) => sub === submenu))
    );
  }
}
