

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDInputCheckbox extends Vue {
  @Prop({ type: String, required: true })
  label!: string;
  @Prop({ type: String, required: false })
  invalidText!: string;
  @Prop({ type: Boolean, required: false, default: false })
  invalid!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  value!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;
}
