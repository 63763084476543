
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { debounce } from '@minddocdev/lib-components/utils';

export default function windowResizeHandler(selector: string) {
  @Component
  class ResizeHandlerMixin extends Vue {
    breakpointWidth= 0;
    useMobileMenu= false;
    private debouncedWindowResize: () => void;
    created() {
      this.debouncedWindowResize = debounce(this.onWindowResize, 100);
      window.addEventListener('resize', this.debouncedWindowResize);
    }
    mounted() {
      this.onWindowResize();
    }
    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedWindowResize);
    }
    onWindowResize() {
      const { scrollWidth: contentSize, clientWidth: itemSize } = 
          document.querySelector(selector) as HTMLElement;
      if (contentSize > itemSize && !this.useMobileMenu) {
        this.breakpointWidth = window.innerWidth;
        this.useMobileMenu = true;
      } else if (this.breakpointWidth < window.innerWidth && this.useMobileMenu){
        this.useMobileMenu = false;
      }
    }
  }
  return ResizeHandlerMixin;
};
