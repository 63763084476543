










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDProgressBar extends Vue {
  @Prop({ required: false, type: String, default: 'blue' })
  color!: string;
  /**
   * Value between 0 and 100
   */
  @Prop({ required: false, type: Number, default: 100 })
  value!: string;
}
