
























import { Component, Prop, Vue } from 'vue-property-decorator';

import MDInputGMapsAddress from '@minddocdev/lib-components/components/google/MDInputGMapsAddress.vue';
import MDInputDate from '@minddocdev/lib-components/components/MDInputDate.vue';
import MDInputText from '@minddocdev/lib-components/components/MDInputText.vue';

import { ValidationProvider } from 'vee-validate';

@Component({
  components: { MDInputGMapsAddress, MDInputDate, MDInputText, ValidationProvider },
  name: 'MDVInput',
})
export default class MDVInput extends Vue {
  @Prop({ type: String, required: false, default: 'off' })
  autocomplete!: string;
  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;
  @Prop({ type: String, required: false })
  label!: string;
  @Prop({ type: String, required: false, default: '' })
  note!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconSrc!: string;
  @Prop({ type: String, required: false, default: '' })
  noteIconAlt!: string;
  @Prop({ type: String, required: false, default: '' })
  placeholder!: string;
  @Prop({ type: String, required: true })
  rules!: string;
  @Prop({ type: Boolean, required: false, default: true })
  showError!: boolean;
  @Prop({ type: String, required: false, default: 'text' })
  type!: string;
  @Prop({ type: [String, Date, Object], required: false, default: '' })
  value!: string;

  get component() {
    switch (this.type) {
      case 'date':
        return 'MDInputDate';
      case 'address':
        return 'MDInputGMapsAddress';
      default:
        return 'MDInputText';
    }
  }
}
