




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import mapStyles from '@minddocdev/lib-components/components/google/MapLocationStyles';
import {
  animatedZoomTo,
  findClosestLocationTo,
  getBoundsZoomLevel,
  getCoordinatesFromPostalCode,
  loadGoogleMaps,
} from '@minddocdev/lib-components/utils/gmaps.utils';

@Component
export default class MDGMapWithLocations extends Vue {
  @Prop({ required: false, type: String })
  apiKey!: string;
  // Use an app name to identify the app in gmaps console
  @Prop({ required: false, type: String })
  appName!: string;
  @Prop({ required: true })
  mapCenter!: LatLng;
  @Prop({ required: true })
  locations!: RestTherapy.LocationResDto[];
  /**
   * The zoom value can be float but the map might look blurry.
   * We use 6 which can show almost all of germany on the map
   */
  @Prop({ required: false, default: 6 })
  zoom!: number;
  @Prop({ required: false })
  postalCode!: string;
  @Prop({ required: false, type: Boolean, default: false })
  showLabels!: boolean;

  private googleMap!: any;
  private map!: any;

  markers: any = [];

  async mounted() {
    await this.initializeMap();
    await this.setBoundsToPostalCode();
  }

  async initializeMap() {
    this.googleMap = (await loadGoogleMaps(this.apiKey, this.appName))!.maps;
    const options = {
      disableDefaultUI: true,
      center: this.mapCenter,
      zoom: this.zoom,
    };
    this.map = new this.googleMap.Map(this.$refs['map'], options);
    this.map.mapTypes.set(
      'map_style',
      new this.googleMap.StyledMapType(mapStyles, { name: 'Styled Map' }),
    );
    this.map.setMapTypeId('map_style');
    this.markers = this.locations.map(this.createMarker);
  }

  createMarker(loc: RestTherapy.LocationResDto) {
    const position = new this.googleMap.LatLng(loc.lat, loc.lng);
    const marker = new this.googleMap.Marker({
      customInfo: loc,
      icon: {
        url: '/media/marker.svg',
        size: new this.googleMap.Size(54, 61),
        origin: new this.googleMap.Point(0, 0),
        anchor: new this.googleMap.Point(14, 40),
        scaledSize: new this.googleMap.Size(54, 61),
      },
      label: this.showLabels
        ? {
            className: 'marker__label',
            text: `${loc.street} ${loc.number}, ${loc.zip} ${loc.city}`,
            fontSize: '16px',
            fontFamily: 'Lato, sans-serif',
          }
        : undefined,
      map: this.map,
      optimized: false,
      position,
    });
    // istanbul ignore next
    marker.addListener('click', () => {
      this.$emit('markerClicked', marker.customInfo);
    });

    return marker;
  }

  @Watch('mapCenter')
  updateMapCenter() {
    this.map.setCenter(this.mapCenter);
  }

  @Watch('postalCode')
  async setBoundsToPostalCode() {
    if (this.postalCode?.length > 3 && this.locations.length) {
      const postalCodeLatLng = await getCoordinatesFromPostalCode(this.postalCode);
      const postalCodePoint = new this.googleMap.LatLng(postalCodeLatLng.lat, postalCodeLatLng.lng);
      const closestLocation = findClosestLocationTo(postalCodePoint, this.locations);
      const bounds = new this.googleMap.LatLngBounds();
      const mapHtmlNode = this.$refs['map'] as HTMLElement;
      const mapDimensions = {
        width: mapHtmlNode!.clientWidth,
        height: mapHtmlNode!.clientHeight,
      };
      bounds.extend(postalCodePoint);
      bounds.extend(closestLocation);

      this.map.panTo(bounds.getCenter());
      animatedZoomTo(this.map, getBoundsZoomLevel(bounds, mapDimensions));
    }
  }
}
