import {
  DIAGNOSIS,
  DISEASE_SPECIALIZATION,
  GENDERS,
  LANGUAGES,
  THERAPY_SPECIALIZATIONS,
  TITLES,
} from '@minddocdev/lib-web/lang/global';
import validation from '@minddocdev/locale/Validation.yaml';
import Routes from '@minddocdev/ui-accounts/lang/Routes.yaml';

const messages = {
  en: {
    ...Routes.en,
    ...validation.en,
    ...DIAGNOSIS.en,
    ...DISEASE_SPECIALIZATION.en,
    ...GENDERS.en,
    ...LANGUAGES.en,
    ...TITLES.en,
    ...THERAPY_SPECIALIZATIONS.en,
  },
  de: {
    ...Routes.de,
    ...validation.de,
    ...DIAGNOSIS.de,
    ...DISEASE_SPECIALIZATION.de,
    ...GENDERS.de,
    ...LANGUAGES.de,
    ...TITLES.de,
    ...THERAPY_SPECIALIZATIONS.en,
  },
};

export default messages;
