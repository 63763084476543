


























import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import MDMiniCalendar from '@minddocdev/lib-components/components/date-time/MDMiniCalendar.vue';

import { Dayjs } from 'dayjs';

@Component({
  components: { MDMiniCalendar },
})
export default class MDDatePicker extends Vue {
  @Prop({ required: false, type: [Date, String, Object], default: () => null })
  date!: Date | string;

  @Ref()
  calendar!: {
    innerDate: Dayjs;
    prevMonth(): void;
    nextMonth(): void;
  };

  header = '';

  mounted() {
    this.updateHeader();
    this.$watch('$refs.calendar.innerDate', this.updateHeader.bind(this));
  }

  updateHeader() {
    this.header = this.calendar.innerDate.format('MMMM YYYY');
  }

  prevMonth() {
    this.calendar!.prevMonth();
  }

  nextMonth() {
    this.calendar!.nextMonth();
  }
}
