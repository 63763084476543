import { getMindDocAppUrl, MindDocApp } from '@minddocdev/lib-web/browser/url.utils';

import axios, { AxiosRequestConfig } from 'axios';

// TODO: move this to types file
export enum APIErrorCodes {
  UserAlreadyRegistered = 'user_already_registered',
}

const serverUrl = getMindDocAppUrl(
  MindDocApp.Api,
  window.location.hostname,
  process.env.VUE_APP_BASE_API_URL,
);

const requestConfig: AxiosRequestConfig = {
  timeout: 10000,
  withCredentials: serverUrl !== '',
};

/**
 * Axios instance for authenticated request to users
 */
export const usersApi = axios.create({ ...requestConfig, baseURL: `${serverUrl}/users` });

export const authUsersApi = axios.create({ ...requestConfig, baseURL: `${serverUrl}/users` });

export const therapyApi = axios.create({ ...requestConfig, baseURL: `${serverUrl}/therapy` });

export const authTherapyApi = axios.create({ ...requestConfig, baseURL: `${serverUrl}/therapy` });
