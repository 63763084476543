import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';

import { Jwt } from '@minddocdev/lib-common/users';
import { postBookableSlotDiagnostic } from '@minddocdev/lib-web/api/services/calendar';
import { postProfileEmail } from '@minddocdev/lib-web/api/services/users';
import {
  getAppForGroup,
  getMindDocAppUrl,
  MindDocApp,
} from '@minddocdev/lib-web/browser/url.utils';
import { extractTokensFromCookies } from '@minddocdev/lib-web/utils/auth.utils';
import Demo from '@minddocdev/ui-accounts/views/Demo.vue';
import Login from '@minddocdev/ui-accounts/views/Login.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

export async function consumeDiagnosticOTP(to: Route, _: any, next: any): Promise<void> {
  const { otp, bookableSlotUuid = '' } = to.params;
  try {
    /* istanbul ignore next */
    addBreadcrumb({
      category: 'email-confirmation',
      message: `Confirming email with slot ${bookableSlotUuid}`,
    });
    const jwt = await postProfileEmail(otp, bookableSlotUuid);
    let path = 'diagnostic-appointment/book-new';
    if (bookableSlotUuid !== '') {
      path = `diagnostic-appointment/${bookableSlotUuid}`;
      // Try to confirm the appointment. If the appointment has been taken,
      // ui-therapy will ask the patient to select a new one. So here we ignore the errors.
      await postBookableSlotDiagnostic(bookableSlotUuid, jwt.accessToken).catch(null);
    }
    const therapyApp = getMindDocAppUrl(MindDocApp.Therapy, window.location.hostname);
    window.location.href = `${therapyApp}/${path}`;
  } catch (e) {
    /* istanbul ignore next */
    captureException(e);
    // Reuse the message screen for invalid invitation link
    next('/email-verification/invalid');
  }
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/app-subscription',
    name: 'app-subscription-login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@minddocdev/ui-accounts/views/ForgotPassword.vue'),
  },
  {
    path: '/forgot-password/:otp',
    name: 'reset-password',
    component: () => import('@minddocdev/ui-accounts/views/ResetPassword.vue'),
    props: true,
  },
  {
    path: '/app-subscription/email-verification/:otp',
    name: 'app-subscription-email-verification',
    component: () => import('@minddocdev/ui-accounts/views/EmailVerification.vue'),
    props: true,
  },
  {
    path: '/email-verification/:otp',
    name: 'email-verification',
    component: () => import('@minddocdev/ui-accounts/views/EmailVerification.vue'),
    props: true,
  },
  {
    path: '/change-email-verification/:otp',
    name: 'change-email',
    component: () => import('@minddocdev/ui-accounts/views/ChangeEmail.vue'),
    props: true,
  },
  {
    path: '/onboarding/:otp',
    name: 'onboarding',
    component: () => import('@minddocdev/ui-accounts/views/Onboarding.vue'),
    props: true,
  },
  {
    path: '/diagnostic-appointment/:bookableSlotUuid/:otp',
    name: 'diagnostic-appointment',
    beforeEnter: consumeDiagnosticOTP,
  },
  {
    path: '/diagnostic-appointment/book-new/:otp',
    name: 'diagnostic-appointment-new',
    beforeEnter: consumeDiagnosticOTP,
  },
  {
    path: '/demo-ref',
    name: 'demo-ref',
    component: Demo,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
});

router.beforeEach((to, _, next) => {
  const links = document.querySelectorAll("link[rel*='icon']");
  const faviconUrl =
    to.name === 'app-subscription-login'
      ? '/icons/favicon-128x128_Coral.png'
      : '/icons/favicon-128x128.png';

  links.forEach((link: any) => {
    if (link.href.includes('favicon')) {
      link.href = faviconUrl;
    }
  });
  document.title = router.app.$t(to.name!) as string;
  const { accessToken, refreshToken } = extractTokensFromCookies(document.cookie);
  const hostname = window.location.hostname;
  if (!!accessToken && !!refreshToken && !hostname.includes('diga')) {
    const { group } = jwtDecode(accessToken) as Jwt;
    const redirectUrl = getAppForGroup(group, window.location.hostname);
    window.location.href = redirectUrl;
  } else {
    next();
  }
});

export default router;
