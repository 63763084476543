/* eslint-disable @typescript-eslint/no-namespace, @typescript-eslint/no-unused-vars */
import { valuesEnum } from './utils';

export enum FilterOperator {
  ArrayContains = 'array_contains',
  Eq = 'eq',
  Fuzzy = 'fuzzy',
  In = 'in',
  InObjectArray = 'in_object_array',
  IsContainedByArray = 'is_contained_by_array',
  IsNull = 'is_null',
  Lt = 'lt',
  Lte = 'lte',
  ILike = 'ilike',
  Like = 'like',
  Gt = 'gt',
  Gte = 'gte',
}

export const filterOperatorOptions = valuesEnum(FilterOperator);

/* istanbul ignore next */
export const comparatorOptions = ['AND', 'OR'] as const;
/* istanbul ignore next */
export const orderByOptions = ['ASC', 'DESC', 'asc', 'desc'] as const;

export declare namespace Search {
  type Comparator = typeof comparatorOptions[number];

  interface AccentOptions {
    insensitive: boolean;
  }

  interface FuzzyOptions {
    enabled: boolean;
    sensitivity?: number;
  }

  interface FilterExtras {
    accent: AccentOptions;
    fuzzy: FuzzyOptions;
  }

  interface Filter {
    field: string;
    operator: FilterOperator;
    relation?: string;
    value: string[]; // We use a string array to simplify the gRPC message
  }

  type OrderBy = typeof orderByOptions[number];

  interface Pagination {
    page: number;
    perPage: number;
  }

  interface Sort {
    sortBy: string;
    orderBy: OrderBy;
  }

  interface SearchParams {
    comparator?: Comparator;
    filterExtras?: FilterExtras;
    filters?: Filter[];
    pagination?: Pagination;
    sorting?: Sort[];
  }

  type SearchParamsRaw = {
    comparator?: string;
    filterExtras?: string;
    filter?: string;
    page?: number;
    perPage?: number;
    sort?: string;
  };
}
