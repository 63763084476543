import { google, Loader, LoaderOptions } from 'google-maps';

const MIN_ZOOM_VALUE = 1;
const ZOOM_MAX = 21;
const TRANSITION_LENGTH = 150; // time in ms
const loaderOptions: LoaderOptions = {
  channel: 'admin', // Used for analytics to measure the API usage from this APP
  libraries: ['places', 'geometry'],
};

export async function loadGoogleMaps(apiKey: string, channel: string) {
  if (!window.google) {
    const loader = new Loader(apiKey, { ...loaderOptions, channel });
    return await loader.load();
  }
  return window.google;
}

export async function getCoordinatesFromPostalCode(postalCode: string): Promise<LatLng> {
  const geoCoder = new window.google.maps.Geocoder();
  const address: google.maps.GeocoderRequest = {
    componentRestrictions: {
      postalCode,
      country: 'DE',
    },
  };
  return new Promise((resolve, reject) => {
    geoCoder.geocode(address, (results: any, status: string) => {
      if (status === 'OK') {
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      } else {
        reject(new Error(status));
      }
    });
  });
}
export function findClosestLocationTo(
  point: google.maps.LatLng,
  locations: RestTherapy.LocationResDto[],
): RestTherapy.LocationResDto {
  let minimumDistance = Number.MAX_SAFE_INTEGER; // 2^53-1
  let minIndex = 0;

  locations.forEach((location: RestTherapy.LocationResDto, index: number) => {
    const locationPoint = new window.google.maps.LatLng(location.lat, location.lng);
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      point,
      locationPoint,
    );
    if (distance < minimumDistance) {
      minimumDistance = distance;
      minIndex = index;
    }
  });
  return locations[minIndex];
}

// For reference on this method https://stackoverflow.com/a/13274361
function latitudeToRadians(lat: number) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

function zoom(mapPx: number, worldPx: number, fraction: number) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}
export function getBoundsZoomLevel(bounds: google.maps.LatLngBounds, mapDim: any) {
  const WORLD_DIM = { height: 256, width: 256 };

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const latFraction = (latitudeToRadians(ne.lat()) - latitudeToRadians(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = lngDiff / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
export function animatedZoomTo(map: google.maps.Map, newZoomLevel: number) {
  const mapZoom = map.getZoom();
  // zoom in
  if (newZoomLevel > mapZoom) {
    map.setZoom(mapZoom + MIN_ZOOM_VALUE);
    setTimeout(function () {
      animatedZoomTo(map, newZoomLevel);
    }, TRANSITION_LENGTH);
  } else if (newZoomLevel < mapZoom) {
    // zoom out
    map.setZoom(mapZoom - MIN_ZOOM_VALUE);
    setTimeout(function () {
      animatedZoomTo(map, newZoomLevel);
    }, TRANSITION_LENGTH);
  }
}
