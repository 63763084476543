import MDGMapWithLocations from '@minddocdev/lib-components/components/google/MDGMapWithLocations.vue';
import MDGStaticMap from '@minddocdev/lib-components/components/google/MDGStaticMap.vue';
import MDInputGMapsAddress from '@minddocdev/lib-components/components/google/MDInputGMapsAddress.vue';
import MDButton from '@minddocdev/lib-components/components/MDButton.vue';
import MDChip from '@minddocdev/lib-components/components/MDChip.vue';
import MDCloudinaryImage from '@minddocdev/lib-components/components/MDCloudinaryImage.vue';
import MDDatePicker from '@minddocdev/lib-components/components/MDDatePicker.vue';
import MDDropdownMenu from '@minddocdev/lib-components/components/MDDropdownMenu.vue';
import MDEmptyContent from '@minddocdev/lib-components/components/MDEmptyContent.vue';
import MDFormGroup from '@minddocdev/lib-components/components/MDFormGroup.vue';
import MDFormInput from '@minddocdev/lib-components/components/MDFormInput.vue';
import MDGCSOrCldImage from '@minddocdev/lib-components/components/MDGCSOrCldImage.vue';
import MDInputCheckbox from '@minddocdev/lib-components/components/MDInputCheckbox.vue';
import MDInputDate from '@minddocdev/lib-components/components/MDInputDate.vue';
import MDInputRadio from '@minddocdev/lib-components/components/MDInputRadio.vue';
import MDInputText from '@minddocdev/lib-components/components/MDInputText.vue';
import MDLogo from '@minddocdev/lib-components/components/MDLogo.vue';
import MDModalCloseButton from '@minddocdev/lib-components/components/MDModalCloseButton.vue';
import MDMultiSelect from '@minddocdev/lib-components/components/MDMultiSelect.vue';
import MDNavigationMenu from '@minddocdev/lib-components/components/MDNavigationMenu.vue';
import MDProgressSpinner from '@minddocdev/lib-components/components/MDProgressSpinner.vue';
import MDSearchBox from '@minddocdev/lib-components/components/MDSearchBox.vue';
import MDSelect from '@minddocdev/lib-components/components/MDSelect.vue';
import MDSiteHeader from '@minddocdev/lib-components/components/MDSiteHeader.vue';
import MDTile from '@minddocdev/lib-components/components/MDTile.vue';
import Modal from '@minddocdev/lib-components/components/modal/Modal.vue';
import MultiStepModal from '@minddocdev/lib-components/components/modal/MultiStepModal.vue';
import MDUserTile from '@minddocdev/lib-components/components/user/MDUserTile.vue';
import service, { faceCropOption } from '@minddocdev/lib-components/services/cloudinary';

const cloudinary = {
  service,
  faceCropOption,
};

export {
  cloudinary,
  MDButton,
  MDChip,
  MDCloudinaryImage,
  MDDatePicker,
  MDDropdownMenu,
  MDEmptyContent,
  MDFormGroup,
  MDFormInput,
  MDGCSOrCldImage,
  // Google
  MDGMapWithLocations,
  MDGStaticMap,
  MDInputCheckbox,
  MDInputDate,
  MDInputGMapsAddress,
  MDInputRadio,
  MDInputText,
  MDLogo,
  MDModalCloseButton,
  MDMultiSelect,
  MDNavigationMenu,
  MDProgressSpinner,
  MDSearchBox,
  MDSelect,
  MDSiteHeader,
  MDTile,
  // User
  MDUserTile,
  Modal,
  MultiStepModal,
};
