




import { Component, Prop, Vue } from 'vue-property-decorator';

import service, { faceCropOption } from '@minddocdev/lib-components/services/cloudinary';

import { Transformation } from 'cloudinary-core';

@Component
export default class MDGCSOrCldImage extends Vue {
  /* The Cloudinary public ID of the resource */
  @Prop({ type: String, required: false })
  src!: string;
  /* The image type (for predefined transformations) */
  @Prop({ type: String, required: false, default: '' })
  type!: string;
  /* The transformation or configuration options for the image (for custom ones) */
  @Prop({ required: false, default: null })
  options!: Transformation | Transformation.Options;
  /* The GCS or Cloudinary configuration option  */
  @Prop({ required: true, default: 'cld' })
  mode!: 'gcs' | 'cld';

  private cloudinaryOptions!: Transformation | Transformation.Options;

  get url() {
    return this.src;

    // commented code to use presigned url

    // const imgArr = this.src.split('.');
    // let imgExt;
    // if (imgArr[imgArr.length - 1] == 'png') imgExt = 'png';
    // else if (imgArr[imgArr.length - 1] == 'jpg') imgExt = 'jpg';
    // else if (imgArr[imgArr.length - 1] == 'PNG') imgExt = 'PNG';
    // else if (imgArr[imgArr.length - 1] == 'jpeg') imgExt = 'jpeg';
    // else if (imgArr[imgArr.length - 1] == 'JPEG') imgExt = 'JPEG';
    // else if (imgArr[imgArr.length - 1] == 'JPG') imgExt = 'JPG';
    // const imgsrc =
    //   imgExt == 'png' ||
    //   imgExt == 'jpg' ||
    //   imgExt == 'jpeg' ||
    //   imgExt == 'JPG' ||
    //   imgExt == 'JPEG' ||
    //   imgExt == 'PNG'
    //     ? this.src
    //     : `${this.src}.${imgExt}`;
    // return imgsrc.startsWith('https://storage.googleapis.com') ||
    //   imgsrc.startsWith('https://static.minddoc.com')
    //   ? imgsrc
    //   : `https://storage.googleapis.com/mitsu-minddoc/${imgsrc}`;
  }

  created() {
    if (this.type === 'avatar') {
      this.cloudinaryOptions = {
        width: 150,
        height: 150,
        ...faceCropOption,
      };
    } else {
      this.cloudinaryOptions = this.options || {};
    }
  }
}
