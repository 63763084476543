



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MDTile',
})
export default class MDTile extends Vue {
  @Prop({ required: true, type: String })
  title!: string;
  @Prop({ required: true, type: String })
  subtitle!: string;
  @Prop({ required: false, type: Boolean, default: false })
  clickable!: boolean;
  @Prop({ required: false, type: Number, default: 0 })
  counter!: number;
}
