import Vue from 'vue';
import Notifications from 'vue-notification'; // LEGACY

import MDButton from '@minddocdev/lib-components/components/MDButton.vue';
import MDCloudinaryImage from '@minddocdev/lib-components/components/MDCloudinaryImage.vue';
import MDLogo from '@minddocdev/lib-components/components/MDLogo.vue';
import MDVInput from '@minddocdev/lib-web/components/inputs/MDVInput.vue';
import { initSentry } from '@minddocdev/lib-web/utils/sentry';
import App from '@minddocdev/ui-accounts/App.vue';
import iconPack from '@minddocdev/ui-accounts/fontawesome-iconpack';
import i18n from '@minddocdev/ui-accounts/i18n';
import { init as initializeVeeValidate } from '@minddocdev/ui-accounts/plugins/vee-validate';
import router from '@minddocdev/ui-accounts/router';

import '@minddocdev/lib-components/fontawesome';
import '@minddocdev/ui-accounts/registerServiceWorker';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

initSentry('https://0d39210dea594d218d742ad361899634@o172767.ingest.sentry.io/2521177', router);

Vue.config.productionTip = false;

// Import only the icons we use to keep bundle size small
library.add(iconPack);

// Global components
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('MDCloudinaryImage', MDCloudinaryImage);
Vue.component('MDButton', MDButton);
Vue.component('MDLogo', MDLogo);
Vue.component('MDVInput', MDVInput);

Vue.use(Notifications);

initializeVeeValidate();

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
