









import { Component, Vue } from 'vue-property-decorator';

/**
 * Default MindDoc close button for modal.
 */
@Component
export default class MDModalCloseButton extends Vue {}
