











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MDFormGroup extends Vue {
  @Prop({ type: String, required: true })
  title!: string;
  @Prop({ type: String, required: false, default: '' })
  notation!: string;
}
