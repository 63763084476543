import Vue from 'vue';

import { lazyPassive, registerRules } from '@minddocdev/lib-web/validation';
import i18n from '@minddocdev/ui-accounts/i18n';

import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';

export function init(): void {
  setInteractionMode('lazyPassive', lazyPassive);
  registerRules(i18n, ['email', 'date', 'max', 'min_age', 'required', 'phone_number']);

  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);
}
