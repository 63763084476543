/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { DirectiveOptions, VNodeDirective } from 'vue';

// Extend global HTMLElement interface
declare global {
  interface HTMLElement {
    __click_outside: {
      handler: Function;
      callback: Function;
    };
  }
}

function validate(binding: VNodeDirective) {
  if (typeof binding.value !== 'function') {
    throw new Error(`[click-outside] ${binding.expression} binding must be a function`);
  }
}

const options: DirectiveOptions = {
  bind: (el, binding, vnode) => {
    validate(binding);
    const callback = binding.value;
    const vm = vnode.context;

    function onDocumentClick(ev: Event & { path: EventTarget[] }) {
      if (!vm) return;

      // path is not standard, it could also be composedPath
      const path = ev.path || ev.composedPath();
      if ((path && path.indexOf(el) < 0) || !el.contains(ev.target as Node)) {
        el.__click_outside.callback.call(vm, ev);
      }
    }

    el.__click_outside = {
      callback,
      handler: onDocumentClick,
    };

    document.addEventListener('click', el.__click_outside.handler as any);
  },

  update: (el, binding) => {
    validate(binding);
    el.__click_outside.callback = binding.value;
  },

  unbind: (el: any) => {
    if (el.__click_outside) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      document.removeEventListener('click', el.__click_outside.handler as any);
      delete el.__click_outside;
    }
  },
};

export default options;
