

















import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator';

import { Modal } from '@minddocdev/lib-components';
import { FooterActions } from '@minddocdev/lib-components/components/modal/types';

@Component({
  components: {
    Modal,
  },
})
export default class MultiStepModal extends Vue {
  @Prop({ required: true })
  currentStep: string;
  @Prop({ required: false, default: false })
  showBackButton: boolean;
  @Prop({ required: false, default: false })
  hideCloseButton: boolean;

  footerActions: FooterActions = {};
  headerText = '';

  @Provide()
  defineActions(actions: FooterActions) {
    this.footerActions = actions;
  }

  @Provide()
  defineHeader(header: string) {
    this.headerText = header;
  }

  @Watch('currentStep', { immediate: true })
  onCurrentStepChange() {
    this.footerActions = {};
  }
}
