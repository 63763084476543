// TODO: Keep only the icons we need for the admin panel
import { faArrowAltFromBottom, IconPack } from '@fortawesome/pro-light-svg-icons';

/**
 * The icons used by the application.
 *
 * Explicitly selecting icons offer the advantage of only bundling the icons that we use in the
 * final bundled file.
 *
 * When we need to add a new icon to our templates, we need to add it to our IconPack, too.
 *
 * For more information, see
 * https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
 */
const iconPack: IconPack = {
  // Light icons
  faArrowAltFromBottom,
};

export default iconPack;
